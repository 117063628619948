/**
 * Created by johnny on 15/08/16
 */

import {assign} from 'lodash'

export let PortalEndpoints = {
  PUBLIC_LOAD_MAKE_AND_MODEL: 'public/api/inventory/car/loadMakeAndModel',
  PUBLIC_SEARCH_INVENTORY: 'public/api/inventory/search',
  PUBLIC_SAVE_MESSAGE: 'public/api/message',
  PUBLIC_LOAD_CAR_BY_VIN: 'public/api/inventory/car',
  PUBLIC_LOAD_LOCATIONS: 'public/api/locations',
  PROCESS_FINANCE_FORM: 'public/api/processFinanceForm',
  LOAD_DEALER_MEDIA_BY_ID: 'public/api/dealer/media/',
  LOAD_PUBLISHED_TESTIMONIALS: 'public/api/loadTestimonials',
  LOAD_PUBLIC_FORM_BY_CODE: 'public/api/forms',
  PUBLIC_INITIALIZE_PAYMENT_PROCESSOR: 'public/api/payment/initialize',
  PUBLIC_INVENTORY_ITEM_API: 'public/api/inventoryItem',
  PUBLIC_SAVE_DEALER: 'public/api/dealers',
  VERIFY_BUSINESS_NAME: 'public/api/dealers/verifyBusinessName',
  VERIFY_ADMIN_EMAIL: 'public/api/dealers/verifyAdminEmail',
}

export function getServerUrl() {
  // return 'http://localhost:9000/';
  if (window.baseHref) {
    return window.baseHref
  }
  return ''
}

function doGet(url, body, props) {
  props = props || {}
  return fetch(
    url,
    assign({
      method: 'get',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)
}

function doPost(url, body, props) {
  props = props || {}
  //body = body || '';
  return fetch(
    url,
    assign({
      method: 'post',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)

}

function doDelete(url, body, props) {
  props = props || {}
  return fetch(
    url,
    assign({
      method: 'delete',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)
}

export function getCarSpecsByVin(vin) {
  /*Application: DealerPro
   Key: qs4pu33cnafcwtamghqtpa5u
   Secret: bdYug34YgBVKbsdK7Fws3s6d*/
  const url = `https://api.edmunds.com/api/vehicle/v2/vins/${vin}?fmt=json&api_key=qs4pu33cnafcwtamghqtpa5u`
  return doGet(url)
}

function doPut(url, body, props) {
  props = props || {}
  //body = body || '';
  return fetch(
    url,
    assign({
      method: 'put',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)
}

function checkStatus(response) {
  return response.json().then((data) => {
    let newResponse = {...data, status: response.status, ok: response.ok}
    if (response.status >= 200 && response.status < 300) {
      return newResponse
    } else {
      throw newResponse
    }
  })
}

export function loadMakeAndModelApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.PUBLIC_LOAD_MAKE_AND_MODEL}`)
}


export function searchInventoryPublicApi(dealerId, data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.PUBLIC_SEARCH_INVENTORY}/${dealerId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveMessage(data) {
  return doPost(getServerUrl() + PortalEndpoints.PUBLIC_SAVE_MESSAGE, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadCarByVinPublicApi(vin, dealerId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.PUBLIC_LOAD_CAR_BY_VIN}/${vin}/${dealerId}`)
}


export function loadLocationsPublicApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.PUBLIC_LOAD_LOCATIONS}`)
}

export function processFinanceFormApi(data) {
  return doPost(`${getServerUrl() + PortalEndpoints.PROCESS_FINANCE_FORM}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadDealerMedia(dealerId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_DEALER_MEDIA_BY_ID}${dealerId}`)
}


export function loadPublishedTestimonials() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_PUBLISHED_TESTIMONIALS}`)
}


export function loadPublicFormByCodeApi(formId, objectId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_PUBLIC_FORM_BY_CODE}/${formId}/${objectId}`)
}

export function publicInitializePaymentProcessor() {
  return doGet(`${getServerUrl()}${PortalEndpoints.PUBLIC_INITIALIZE_PAYMENT_PROCESSOR}`)
}

export function publicLoadSubscriptions(){
  return doGet(`${getServerUrl()}${PortalEndpoints.PUBLIC_INVENTORY_ITEM_API}/subscriptions`)
}

export function publicSaveNewDealerAndAccountOwner(body) {
  let data = new FormData()
  const { fileDealer, dealer, fileAccountOwner, accountOwner, fileDealerIcon, orderPayment } = body

  if (fileDealer && fileDealer.length > 0) {
    fileDealer.forEach((file, index) => {
      data.append('dealer', file, file.name)
    })
  }

  if (fileAccountOwner && fileAccountOwner.length > 0) {
    fileAccountOwner.forEach((file, index) => {
      data.append('user', file, file.name)
    })
  }

  if (fileDealerIcon && fileDealerIcon.length > 0) {
    data.append('dealerIcon', fileDealerIcon[0], fileDealerIcon[0].name)
  }

  if (dealer)
    data.append('dealer', JSON.stringify(dealer))

  if (accountOwner)
    data.append('user', JSON.stringify(accountOwner))

  if (orderPayment)
    data.append('orderPayment', JSON.stringify(orderPayment))

  return doPost(`${getServerUrl()}${PortalEndpoints.PUBLIC_SAVE_DEALER}`, data, { headers: {} })
}

export function verifyBusinessName(businessName) {
  return doGet(`${getServerUrl()}${PortalEndpoints.VERIFY_BUSINESS_NAME}?businessName=${businessName}`)
}

export function verifyAdminEmail(adminEmail) {
  return doGet(`${getServerUrl()}${PortalEndpoints.VERIFY_ADMIN_EMAIL}?adminEmail=${adminEmail}`)
}