import React, {Component, useEffect, useState} from 'react'
import { ReactSVG } from 'react-svg'
import {
  DEALERS_BY_PAGE,
  DEALERS_BY_PAGE_BY_DEFAULT,
  DEALER_STATUS_ACTIVE,
  DEALER_STATUS_INACTIVE,
  TAB_DEALER,
  TAB_SUPER_ADMIN,
  ERROR_DUPLICATE_CODE,
  DOMAIN_URL_KEY, DEALER_ICON, siteColorAdmin,
  TAB_NEW_DEALER_TIER,
  TAB_NEW_DEALER_PAYMENT_INFO, paymentTypes, DUPLICATE_FIELDS, TAB_FOR_DUPLICATE_FIELDS
} from '../../../Constants'
import { Popover } from '../../util/ComponentUtils'
import {
  loadAllDealers,
  updateDealerStatus,
  saveNewDealerAndAccountOwner,
  verifySubdomain,
  initializePaymentProcessor,
  loadSubscriptions
} from '../../../Api'
import GenericModalConfirm from '../../util/GenericModalConfirm.jsx'
import { Modal } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import InputElement from 'react-input-mask'
import { isEmpty, cloneDeep, debounce } from 'lodash'
import { browserHistory } from 'react-router'
import DPDropzone from '../../../common/DPDropzone'
import DPTable, { DPTableActions, DPTableLogoImage } from '../../../common/DPTable'
import DPSelectionDropDown from '../../../common/DPSelectionDropDown'
import { checkValidEmail, checkValidPhone } from '../../../../common/ValidationUtil'
import {cleanUpBraintree, getAnchorEl, getNewDate, loadScripts, validateError} from '../../util/Utils'
import {
    pathServer,
    optionState,
    EXT_PNG,
    EXT_OTHER_IMG,
    EXT_IMG,
    TIME_FIVE_SECONDS,
    SUCCESSFUL_CODE,
} from '../../../../common/Constants'
import { DPPagination } from '../../../../common/DPPagination'
import DPPage from '../../../common/DPPage'
import ReactDOM from 'react-dom'
import {PaymentModalForm} from '../../setting/billing/PaymentModal'
import Moment from 'moment'
import get from 'lodash/get'
import {getBillAddress} from "../../payment/braintree";
import {setKeyword} from "../../../actions";
import DPButton from "../../../common/DPButton";
import {publicInitializePaymentProcessor} from "../../../../catalog/Api";
import find from "lodash/find";

export const DEFAULT_VALUE_FILE_DEALER_ICON = []
const DEFAULT_VALUE_FILE_DEALER_LOGO = []

export const ACCESS = {
  PUBLIC: 'public',
  ADMIN: 'admin'
}

export const BoydDealer = ({
                             save,
                             onHide,
                             dealer,
                             anchorEl,
                             closePopoverSelect,
                             onDrop,
                             file,
                             onChange,
                             statusPopoverValue,
                             statePopoverValue,
                             dealerName,
                             hasChange,
                             errors,
                             deleteFile,
                             showPopoverDropDown,
                             selectPopoverDropDown,
                             selectOptionPopover,
                             showPopoverDropDownModal,
                             source,
                             updateSetup,
                             fileDealerIcon
                           }) => {

  const {logoUrl, name, address1, address2, zipCode, phoneNumber, email, city, status, siteIconUrl} = dealer
  const siteIconToDisplay = !isEmpty(siteIconUrl)
    ? siteIconUrl
    : fileDealerIcon.length > 0
      ? URL.createObjectURL(fileDealerIcon[0])
      : ''
  return (
    <div className="panel-content">
      {
        source === TAB_DEALER ?
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon"
                    onClick={onHide}/> : null
      }
      <div className="panel-top">
        <label className="label-name">{dealerName}</label>
      </div>
      <div className="panel-bottom">
        <div className="panel-left-edit">
          <label>Logo</label>
          <div>
            <div className="logo-dealer-content">
              {
                !isEmpty(logoUrl) ?
                  <img src={logoUrl} className="img-dealer"/>
                  :
                  file.length > 0 ?
                    <img src={URL.createObjectURL(file[0])} className="img-dealer"/> :
                    <Dropzone onDrop={onDrop} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                      {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="dropzone-container">
                          <input {...getInputProps()} />
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-customer"/>
                        </div>
                      )}
                    </Dropzone>
              }
            </div>
            <div className="container-icon-image">
              <Dropzone onDrop={(file) => onDrop(file, TAB_DEALER)}
                        accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className="dropzone-container">
                    <input {...getInputProps()} />
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"} className="icon-image-content"
                              beforeInjection={svg => svg.classList.add("icon-image")}/>
                  </div>
                )}
              </Dropzone>
              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="icon-image-content"
                        beforeInjection={svg => svg.classList.add("icon-image")} onClick={deleteFile}/>
            </div>
          </div>

          {/* Dealer Icon Section */}
          <div className="row-icon">
            <label>Site Icon</label>
            <span className="description-icon">
              <DPDropzone
                onDrop={(file) => onDrop(file, DEALER_ICON)}
                extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                dropZoneClassName={'drop-zone-element'}
                divClassName={'dropzone-container'}
                multiple={false}
              >
                <a className="upload-icon">Upload one here!</a>
              </DPDropzone>
            </span>
            <div>
              <div className="sub-description-icon">
                <span>The icons on the site must be square and less than 512 x 512 pixels</span>
              </div>
              <div className="col-icon-browser">
                {
                  isEmpty(siteIconToDisplay)
                    ? <ReactSVG src={pathServer.PATH_IMG + "icon/ic_photo.svg"} className="icon-image"
                                beforeInjection={svg => svg.classList.add('icon-photo')}/>
                    : <React.Fragment>
                      <img src={siteIconToDisplay} className="icon-image"/>
                      <div className="browser-icon">
                        <ReactSVG
                          src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                          className="logo-action-content"
                          onClick={() => deleteFile(DEALER_ICON)}
                          beforeInjection={svg => svg.classList.add('logo-action')}
                        />
                      </div>
                    </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="panel-right-edit">
          <div className="first-row">
            <div className="row-edit">
              <div className="colR27">
                <div style={{display: "flex"}}>
                  <label>Name</label>
                  <span className="label-error">*</span>
                </div>
                <div className="">
                  <input type="text"
                         className={errors.name ? "form-control finance-input has-error" : "form-control finance-input"}
                         id="name" value={name} onChange={(evt) => onChange("name", "update", evt)}/>
                  {errors.name ?
                    <p className="help-block">{getErrorMessage("Name")}</p> : null}
                </div>
              </div>
              {
                source === TAB_DEALER ?
                  <div className="container-status">
                    <label>Status</label>
                    <div className="select" id="selectStatus" onClick={(evt) => {
                      showPopoverDropDownModal("selectStatus", evt)
                    }}>
                      <div className="container-label">
                        <label className="label-status">{statusPopoverValue}</label>
                      </div>
                      {
                        showPopoverDropDown && selectPopoverDropDown === "selectStatus" ?
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                    className="icon-arrow-content"
                                    beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                    className="icon-arrow-content"
                                    beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                      }
                    </div>
                  </div> : null
              }
            </div>
            <div className="row-edit">
              <div className="colR27">
                <div style={{display: "flex"}}>
                  <label>Address</label>
                  <span className="label-error">*</span>
                </div>
                <div className="">
                  <input type="text"
                         className={errors.address1 ? "form-control finance-input has-error" : "form-control finance-input"}
                         id="address1"
                         value={address1} onChange={(evt) => onChange("address1", "update", evt)}/>
                  {errors.address1 ?
                    <p className="help-block">{getErrorMessage("Address")}</p> : null}
                </div>
              </div>
              <div className="colR27">
                <label>Address 2</label>
                <input type="text" className="form-control" id="address2" value={address2}
                       onChange={(evt) => onChange("address2", "update", evt)}/>
              </div>
              <div className="colR16">
                <label>City</label>
                <input type="text" className="form-control" id="city" value={city}
                       onChange={(evt) => onChange("city", "update", evt)}/>
              </div>
              <div className="container-select">
                <label>State</label>
                <div className="select" id="selectState" onClick={(evt) => {
                  showPopoverDropDownModal("selectState", evt)
                }}>
                  <div className="container-label">
                    <label className="label-status-location">{statePopoverValue}</label>
                  </div>
                  {
                    showPopoverDropDown && selectPopoverDropDown === "selectState" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content"
                                beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content"
                                beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                  }
                </div>
              </div>
              <div className="colR12">
                <label>Zip</label>
                <input type="text" className="form-control" id="zipCode" value={zipCode} maxLength={5}
                       onChange={(evt) => onChange("zipCode", "update", evt)}/>
              </div>
            </div>
          </div>
          <div className="second-row">
            <div className="container-title-contact">
              <label className="title-contact">Dealer contact information</label>
            </div>
            <div className="colR27">
              <div style={{display: "flex"}}>
                <label>Phone number</label>
                <span className="label-error">*</span>
              </div>
              <div className="">
                <InputElement type="text"
                              className={errors.phoneNumber ? "form-control finance-input has-error" : "form-control finance-input"}
                              id="phone"
                              value={phoneNumber} onChange={(evt) => onChange("phoneNumber", "update", evt)}
                              mask="(999) 999-9999"/>
                {errors.phoneNumber ?
                  <p className="help-block">{getErrorMessage("Phone number")}</p> : null}
              </div>
            </div>
            <div className="colR40">
              <div style={{display: "flex"}}>
                <label>Email</label>
                <span className="label-error">*</span>
              </div>
              <div className="">
                <input type="text"
                       className={errors.email ? "form-control finance-input has-error" : "form-control finance-input"}
                       id="email"
                       value={email} onChange={(evt) => onChange("email", "update", evt)}/>
                {errors.email ?
                  <p className="help-block">{getMessageInvalid("Email")}</p> : null}
              </div>
            </div>
            <div className="container-button">
              {
                source === TAB_DEALER ?
                  null :
                  <button onClick={() => updateSetup(false, 1)} className="btn-skip">
                    Skip
                  </button>
              }
              <button onClick={save} className="btn-save" disabled={!hasChange}
                      style={hasChange ? {backgroundColor: "#4ad991"} : {backgroundColor: "#A4AFB7"}}>Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {
        showPopoverDropDown ?
          <Popover anchorEl={anchorEl}
                   child={selectPopoverDropDown === "selectState" ?
                     <DPState selectOptionPopover={selectOptionPopover} statePopoverValue={statePopoverValue}
                              object={"update"}/> :
                     <DPStatusModal selectOptionPopover={selectOptionPopover} statusValue={status} object={"update"}/>}
                   classPopover="popover-box-message"
                   handleRequestClose={closePopoverSelect}/> : null
      }
    </div>
  )
}

export const dealerStatuses = [
  {
    id: 'active',
    value: DEALER_STATUS_ACTIVE,
    displayValue: "Active",
    color: "#4AD991"
  },
  {
    id: 'inactive',
    value: DEALER_STATUS_INACTIVE,
    displayValue: "Inactive",
    color: "#FF6565"
  }
]

const nextMessage = "to inactive? Its users won't be able to use the application until it's active again"

function getMessage(dealerName, isActive) {
  return `Are you sure you want to set the status of ${dealerName} ${isActive ? "to active?" : nextMessage}`
}

const Body = ({active, onHide, changeStatus, dealerName}) => {
  return (
    <div className="panel-right-modal-dealer" style={{width: '60%'}}>
      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close_circle.svg"} className="icon-close-content"
                beforeInjection={svg => svg.classList.add("icon-close")} onClick={onHide ? onHide : null}/>
      <div className="container-message">
        <p>{getMessage(dealerName, active)}</p>
      </div>
      <div className="container-button">
        <button className={active ? "btn-activate" : "btn-deactivate"}
                onClick={changeStatus ? changeStatus : null}>{active ? 'Activate now' : 'Deactivate now'}</button>
      </div>
    </div>
  )
}

// @todo: check this
// const DPStatus = ({statusSelected, selectStatus}) => {
//   let options = [];
//   dealerStatuses.forEach((status, index) => {
//     if (status.value === statusSelected) {
//       options.push(
//         <li key={index} className="item-selected" onClick={() => selectStatus(status.value)}>
//           {status.displayValue}
//         </li>
//       )
//     } else {
//       options.push(
//         <li key={index} className="change-submenu-item" onClick={() => selectStatus(status.value)}>
//           {status.displayValue}
//         </li>
//       )
//     }
//   });
//   return (
//     <div className="popover-general-message">
//       <div className="popover-general-message-sub">
//         <u>
//           {options}
//         </u>
//       </div>
//     </div>
//   )
// };

export function validInputsNotRequired(value, checkValid) {
  if (isEmpty(value)) {
    return false
  } else {
    return !checkValid(value)
  }
}

export const fieldsRequiredUpdate = [{key: "name", type: "required"},
  {key: "address1", type: "required"},
  {key: "phoneNumber", type: "required"},
  {key: "email", type: "required"},
  {key: DOMAIN_URL_KEY, type: "required"}]

export function getErrorMessage(name) {
  return name + ' is required'
}

export function getMessageInvalid(name) {
  return 'Please enter a valid ' + name
}

export const DPState = ({selectOptionPopover, statePopoverValue, object}) => {
  let options = []
  optionState.forEach((state, index) => {
    if (state.displayValue === statePopoverValue) {
      options.push(
        <li key={index} id={"option" + index} className="item-selected" onClick={() => {
          selectOptionPopover("state", state.id, state.displayValue, object)
        }}>
          {state.displayValue}
        </li>)
    } else {
      options.push(
        <li key={index} id={"option" + index} className="change-submenu-item" onClick={() => {
          selectOptionPopover("state", state.id, state.displayValue, object)
        }}>
          {state.displayValue}
        </li>)
    }
  })
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub" style={{maxHeight: 120}}>
        <u>
          {options}
        </u>
      </div>
    </div>
  )
}

export const DPStatusModal = ({selectOptionPopover, statusValue, object}) => {
  let options = []
  dealerStatuses.forEach((status, index) => {
    if (status.value === statusValue) {
      options.push(
        <li key={index} id={"option" + index} className="item-selected" onClick={() => {
          selectOptionPopover("status", status.value, status.displayValue, object)
        }}>
          {status.displayValue}
        </li>)
    } else {
      options.push(
        <li key={index} id={"option" + index} className="change-submenu-item" onClick={() => {
          selectOptionPopover("status", status.value, status.displayValue, object)
        }}>
          {status.displayValue}
        </li>)
    }
  })
  return (
    <div className="popover-general-message">
      <div className="popover-general-message-sub">
        <u>
          {options}
        </u>
      </div>
    </div>
  )
}

export const TabHeader = (props) => {
  return (
    <div
      className="title-account"
      style={props.isSelected? {borderBottom: '2px solid #2194F1'} : {borderBottom: 'None'}}
    >
      <label className={props.isSelected ? 'label-selected' : 'label-name'}>{props.headerText}</label>
    </div>
  )
}

const ModalAddDealer = ({
                          showModal,
                          onHide,
                          currentTab,
                          file,
                          onDrop,
                          onBlur,
                          errorEmail,
                          errors,
                          onChange,
                          changeTab,
                          dataTestId,
                          showPopoverDropDown,
                          selectPopoverDropDown,
                          selectOptionPopover,
                          showPopoverDropDownModal,
                          statusPopoverValue,
                          statePopoverValue,
                          closePopoverSelect,
                          anchorEl,
                          fileAccountOwner,
                          dealer,
                          accountOwner,
                          errorsAccountOwner,
                          onClick,
                          errorEmailAccountOwner,
                          isLoading,
                          errorFromBD,
                          errorDuplicate,
                          onChangeValueSubdomain,
                          domain,
                          deleteFile,
                          fileDealerIcon,
                          errorMessage
                        }) => {

  const [selectedPlan, setSelectedPlan] = useState(undefined)

  let child = null
  switch (currentTab) {
    case TAB_DEALER:
      child =
        <Dealer file={file} onDrop={onDrop} onBlur={onBlur} errorEmail={errorEmail} errors={errors} onChange={onChange}
                changeTab={changeTab} statusPopoverValue={statusPopoverValue} showPopoverDropDown={showPopoverDropDown}
                showPopoverDropDownModal={showPopoverDropDownModal} selectPopoverDropDown={selectPopoverDropDown}
                statePopoverValue={statePopoverValue} anchorEl={anchorEl} selectOptionPopover={selectOptionPopover}
                closePopoverSelect={closePopoverSelect} dealer={dealer} errorDuplicate={errorDuplicate}
                onChangeValueSubdomain={onChangeValueSubdomain} domain={domain} deleteFile={deleteFile}
                fileDealerIcon={fileDealerIcon}
        />
      break
    case TAB_SUPER_ADMIN:
      child =
        <AccountOwner file={fileAccountOwner} onDrop={onDrop} onBlur={onBlur} errorEmail={errorEmailAccountOwner}
                      errors={errorsAccountOwner}
                      onChange={onChange} changeTab={changeTab} accountOwner={accountOwner}
                      errorFromBD={errorFromBD}
                      errorDuplicate={errorDuplicate}/>
      break
    case TAB_NEW_DEALER_TIER:
      child =
        <TierPage
          changeTab={changeTab}
          setSelectedPlan={(plan) => setSelectedPlan(plan)}
          selectedPlan={selectedPlan}
        />
      break
    case TAB_NEW_DEALER_PAYMENT_INFO:
      child =
        <PaymentMethod
          onClick={onClick}  // To save all information
          onBack={() => changeTab(TAB_NEW_DEALER_TIER, null)}
          selectedPlan={selectedPlan}
          errorMessage={errorMessage}
          showSkipBtn={true}
        />
      break
  }

  const classNameTabs = {
    [TAB_DEALER]: 'modal-add-dealer',
    [TAB_SUPER_ADMIN]: 'modal-add-account-owner',
    [TAB_NEW_DEALER_TIER]: 'modal-select-tier',
    [TAB_NEW_DEALER_PAYMENT_INFO]: 'modal-add-payment-method',
  }

  return (
    <Modal show={showModal} data-testid={dataTestId}
           className={classNameTabs[currentTab]}>
      <Modal.Body>
        {
          isLoading ?
            <div className="loader-container">
              <div className="loader"/>
            </div> : null
        }
        <div className="panel-content" style={isLoading ? {opacity: 0.4} : null}>
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_close.svg"} className="close-modal-icon"
                    onClick={onHide}/>
          <div className="panel-top">
            <div className="panel-top-left">
              <label>New Dealer</label>
            </div>
            <div className="panel-top-right">
              <TabHeader
                isSelected={currentTab === TAB_DEALER}
                headerText={'Dealer info'}
              />
              <TabHeader
                isSelected={currentTab === TAB_SUPER_ADMIN}
                headerText={'Account owner'}
              />
              <TabHeader
                isSelected={currentTab === TAB_NEW_DEALER_TIER}
                headerText={'Tier'}
              />
              <TabHeader
                isSelected={currentTab === TAB_NEW_DEALER_PAYMENT_INFO}
                headerText={'Payment method'}
              />
            </div>
          </div>
          {
            child
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

const Dealer = ({
                  file,
                  onDrop,
                  onBlur,
                  errorEmail,
                  errors,
                  onChange,
                  changeTab,
                  statusPopoverValue,
                  showPopoverDropDown,
                  showPopoverDropDownModal,
                  selectPopoverDropDown,
                  statePopoverValue,
                  anchorEl,
                  selectOptionPopover,
                  closePopoverSelect,
                  dealer,
                  errorDuplicate,
                  onChangeValueSubdomain,
                  domain,
                  deleteFile,
                  fileDealerIcon
                }) => {

  const {name, dealerNumber, address1, address2, zip, phoneNumber, email, city, domainUrl} = dealer
  return (
    <div className="panel-bottom">
      <div className="panel-left">
        <label>Dealer logo</label>
        {file.length > 0 ?
          <div className="new-dealer-photo-container">
            <div className="new-dealer-photo">
              <img src={URL.createObjectURL(file[0])} className="image-dealer"/>
            </div>
          </div>
          :
          <Dropzone onDrop={(file) => onDrop(file, TAB_DEALER)} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                    multiple={false} style={{borderStyle: "none"}}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="new-dealer-photo-container dropzone-container">
                <input {...getInputProps()} />
                <div className="new-dealer-photo">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-dealer"/>
                </div>
              </div>
            )}
          </Dropzone>
        }
        <div className="container-icon-image">
          <Dropzone onDrop={(file) => onDrop(file, TAB_DEALER)} accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="dropzone-container">
                <input {...getInputProps()} />
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_file_upload.svg"} className="icon-image-content"
                          beforeInjection={svg => svg.classList.add("icon-image")}/>
              </div>
            )}
          </Dropzone>
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_delete.svg"} className="icon-image-content"
                    beforeInjection={svg => svg.classList.add("icon-image")} onClick={deleteFile}/>
        </div>

        {/* Icon section */}
        <div className="row-icon">
          <label>Site Icon</label>
          <span className="description-icon">
            Site icons are these found on browser tabs, bookmarks bars and WorkPress mobile applications.
            <DPDropzone
              onDrop={(file) => onDrop(file, DEALER_ICON)}
              extensionFiles={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
              dropZoneClassName={'drop-zone-element'}
              divClassName={'dropzone-container'}
              multiple={false}
            >
              <a className="upload-icon">Upload one here!</a>
            </DPDropzone>
          </span>
          <div>
            <div className="sub-description-icon">
              <span>The icons on the site must be square and less than 512 x 512 pixels</span>
            </div>
            <div className="col-icon-browser">
              {
                fileDealerIcon.length === 0
                  ? <ReactSVG src={pathServer.PATH_IMG + "icon/ic_photo.svg"} className="icon-image"
                              beforeInjection={svg => svg.classList.add('icon-photo')}/>
                  : <React.Fragment>
                    <img src={URL.createObjectURL(fileDealerIcon[0])} className="icon-image"/>
                    <div className="browser-icon">
                      <ReactSVG
                        src={pathServer.PATH_IMG + "icon/ic_delete.svg"}
                        className="logo-action-content"
                        onClick={() => deleteFile(DEALER_ICON)}
                        beforeInjection={svg => svg.classList.add('logo-action')}
                      />
                    </div>
                  </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="panel-right">
        <div className="new-dealer-form">
          <div className="flex-row">
            <InputValue placeholder="Dealer name" errorMessage={errors.name} onBlur={onBlur} name="name"
                        onChange={onChange} dataTestId="input-dealer-name"
                        title="Dealer name" isRequired={true} type={TAB_DEALER} value={name}
                        errorDuplicate={errorDuplicate}/>
            <InputValue placeholder="Dealer number" onBlur={onBlur} name="dealerNumber" onChange={onChange}
                        dataTestId="input-dealer-number"
                        title="Dealer number" isRequired={false} type={TAB_DEALER} value={dealerNumber} maxLength={20}/>
            <div className="container-status">
              <label>Status</label>
              <div className="select" id="selectStatus" data-testid="select-dealer-status" onClick={(evt) => {
                showPopoverDropDownModal("selectStatus", evt)
              }}>
                <div className="container-label">
                  <label className="label-status">{statusPopoverValue}</label>
                </div>
                {
                  showPopoverDropDown && selectPopoverDropDown === "selectStatus" ?
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                              className="icon-arrow-content"
                              beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                              className="icon-arrow-content"
                              beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                }
              </div>
            </div>
            <div className="container-select">
              <label>State</label>
              <div className="select" id="selectState" data-testid="select-dealer-state" onClick={(evt) => {
                showPopoverDropDownModal("selectState", evt)
              }}>
                <div className="container-label">
                  <label className="label-status-location">{statePopoverValue}</label>
                </div>
                {
                  showPopoverDropDown && selectPopoverDropDown === "selectState" ?
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                              className="icon-arrow-content"
                              beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                              className="icon-arrow-content"
                              beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                }
              </div>
            </div>
          </div>
          <div className="flex-row">
            <InputValue placeholder="Street, Apt" errorMessage={errors.address1} onBlur={onBlur} name="address1"
                        dataTestId="input-dealer-address1"
                        onChange={onChange} title="Address" isRequired={true} type={TAB_DEALER} value={address1}/>
            <InputValue placeholder="Street, Apt" onBlur={onBlur} name="address2" onChange={onChange} title="Address 2"
                        dataTestId="input-dealer-address2"
                        isRequired={false} type={TAB_DEALER} value={address2}/>
            <InputValue placeholder="City" onBlur={onBlur} name="city" onChange={onChange} title="City"
                        dataTestId="input-dealer-city"
                        isRequired={false} type={TAB_DEALER} value={city}/>
            <InputValue placeholder="#####" onBlur={onBlur} name="zip" onChange={onChange} title="Zip Code"
                        dataTestId="input-dealer-zip"
                        isRequired={false} type={TAB_DEALER} value={zip}/>
          </div>
          <div className="flex-row-domain">
            <InputValue placeholder="Subdomain" onBlur={onBlur} name={DOMAIN_URL_KEY} onChange={onChangeValueSubdomain}
                        dataTestId="input-dealer-subdomain"
                        title="Subdomain" isRequired={true} type={TAB_DEALER} value={domainUrl}
                        errorMessage={errors.domainUrl} errorDuplicate={errorDuplicate} domain={domain}/>
          </div>
          <div className="flex-row">
            <div className="container-title-contact">
              <label className="title-contact">Dealer contact information</label>
            </div>
          </div>
          <div className="content-dealer-information">
            <div className="content-phone">
              <InputMask placeholder="(###)###-####" errorMessage={errors.phoneNumber} onBlur={onBlur}
                         name="phoneNumber"
                         dataTestId="input-dealer-phone"
                         onChange={onChange} title="Phone number" isRequired={true} type={TAB_DEALER}
                         value={phoneNumber} id={"phoneDealer"}/>
            </div>
            <div className="content-email">
              <InputValue placeholder="Type Email" errorMessage={errors.email} errorEmail={errorEmail}
                          dataTestId="input-dealer-email"
                          onBlur={onBlur} name="email" onChange={onChange} title="Email" isRequired={true}
                          type={TAB_DEALER} value={email} id={"emailDealer"}/>
            </div>
          </div>
        </div>
        <div className="container-button">
          <button
            className={errorDuplicate.domainUrl && errorDuplicate.domainUrl !== "Available" ? "next disabled" : "next"}
            data-testid={'next-add-new-dealer'}
            onClick={() => changeTab(TAB_SUPER_ADMIN, dealer)}
            disabled={errorDuplicate.domainUrl && errorDuplicate.domainUrl !== "Available"}>Next
          </button>
        </div>
      </div>
      {
        showPopoverDropDown ?
          <Popover anchorEl={anchorEl}
                   child={selectPopoverDropDown === "selectState" ?
                     <DPState selectOptionPopover={selectOptionPopover} statePopoverValue={statePopoverValue}
                              object={"new"}/> :
                     <DPStatusModal selectOptionPopover={selectOptionPopover} statusValue={statusPopoverValue}
                                    object={"new"}/>}
                   classPopover="popover-box-message"
                   handleRequestClose={closePopoverSelect}/> : null
      }
    </div>
  )
}

const AccountOwner = ({
                        file,
                        onDrop,
                        onBlur,
                        errorEmail,
                        errorEmailDuplicate,
                        errors,
                        onChange,
                        changeTab,
                        accountOwner,
                        errorFromBD,
                        errorDuplicate
                      }) => {

  let margin = 0
  if (errors.firstName || errors.lastName || errors.phoneNumber || errors.email || errorEmailDuplicate) {
    margin = margin + 20
  }

  const {firstName, lastName, address, description, phoneNumber, email} = accountOwner
  return (
    <div className="panel-bottom">
      <div className="panel-left">
        {file.length > 0 ?
          <div style={{height: 157}}>
            <div className="new-account-owner-photo-container">
              <div className="new-account-owner-photo">
                <img src={URL.createObjectURL(file[0])} className="image-dealer"/>
              </div>
            </div>
          </div>
          :
          <Dropzone onDrop={(file) => onDrop(file, TAB_SUPER_ADMIN)}
                    accept={`.${EXT_IMG},.${EXT_OTHER_IMG},.${EXT_PNG}`}
                    multiple={false} style={{borderStyle: "none"}}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="new-account-owner-photo-container dropzone-container">
                <input {...getInputProps()} />
                <div className="new-account-owner-photo">
                  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_add_photo.svg"} className="img-dealer"/>
                </div>
              </div>
            )}
          </Dropzone>
        }
        <div className="container-button">
          <button className="back" onClick={() => changeTab(TAB_DEALER, null)} style={{marginTop: margin}}>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_back.svg"} className="img-back"/>{"Back"}</button>
        </div>
      </div>
      <div className="panel-right">
        <div className="new-admin-form">
          <div className="flex-row">
            <InputValue placeholder="Type Name" errorMessage={errors.firstName} onBlur={onBlur} name="firstName"
                        onChange={onChange} title="First Name" isRequired={true} type={TAB_SUPER_ADMIN}
                        dataTestId="input-owner-first-name"
                        value={firstName}/>
            <InputValue placeholder="Type Last Name" errorMessage={errors.lastName} onBlur={onBlur} name="lastName"
                        dataTestId="input-owner-last-name"
                        onChange={onChange} title="Last Name" isRequired={true} type={TAB_SUPER_ADMIN}
                        value={lastName}/>
            <InputMask placeholder="(###)###-####" errorMessage={errors.phoneNumber} onBlur={onBlur} name="phoneNumber"
                       onChange={onChange} title="Phone number" isRequired={true} type={TAB_SUPER_ADMIN}
                       dataTestId="input-owner-phone-number"
                       value={phoneNumber}/>
            <InputValue placeholder="Type Email" errorMessage={errors.email} errorEmail={errorEmail}
                        errorEmailDuplicate={errorEmailDuplicate} onBlur={onBlur} name="email" onChange={onChange}
                        dataTestId="input-owner-email"
                        title="Email" isRequired={true} type={TAB_SUPER_ADMIN} value={email}
                        errorDuplicate={errorDuplicate}/>
          </div>
          <div className="flex-row">
            <InputValue placeholder="Street, APT, City and ZIP Code" onBlur={onBlur} name="address" onChange={onChange}
                        dataTestId="input-owner-address"
                        title="Address" isRequired={false} type={TAB_SUPER_ADMIN} value={address}/>
            <InputValue placeholder="Description" onBlur={onBlur} name="description" onChange={onChange}
                        dataTestId="input-owner-description"
                        title="Description" isRequired={false} type={TAB_SUPER_ADMIN} value={description}/>
          </div>
        </div>
        {
          (errorFromBD && (errorFromBD !== "")) ?
            <div className="container-message-error">
              <span className="error-message">{errorFromBD}</span>
            </div> : null
        }
        <div className="container-button">
          <button
            className={'next'}
            onClick={() => changeTab(TAB_NEW_DEALER_TIER, accountOwner)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

const PlanContainer = ({plan, featureNamesList, handleClickOnSelectedLink = null, isSelectedPlan = false}) => {
  const getFormattedValue = (value) => {
    const formatter = new Intl.NumberFormat('en-US')
    return value === -1 ? 'Unlimited' : `${formatter.format(value)}`
  }

  const getElement = (value, additionalText, preText = '') => {
    const valueFormatted = getFormattedValue(value)
    const preTextFormatted =
      valueFormatted === 'Unlimited'
        ? ''
        : (!!preText ? `${preText} ` : '')

    return value &&
      <div>
        <b>{preTextFormatted}{valueFormatted}</b>{` ${additionalText}`}
      </div>
  }

  return (
    <div className='plan-container'>

      <div className='plan-title-section'>
        <div className='plan-title-text'>{plan.name}</div>
        <div
          className={`plan-select-link${isSelectedPlan ? ' plan-selected' : ''}`}
          onClick={handleClickOnSelectedLink}
        >{isSelectedPlan ? 'Selected' : 'Select'}</div>
      </div>

      <div className='plan-body-section'>
        <div className='plan-price-container'>
          <div className='plan-price-currency'>$</div>
          <div className='plan-price-amount'>{plan.price}</div>
          <div className='plan-price-periodicity'>/ month</div>
        </div>
        <div className='plan-price-comment'>Try our features</div>
      </div>

      <div className='plan-features-section'>
        { getElement(plan?.teamMembersQuantity, 'users', 'Up to') }
        { getElement(plan?.listingsQuantity, 'listings') }
        { getElement(plan?.tasksQuantity, 'task on dashboard') }
        {
          featureNamesList && featureNamesList.map(el => {
            return (el.name === 'CRM')
              ? <div key={el.id}>{`${el.name}: `}<b>{getFormattedValue(plan?.listingsQuantity)}</b>{` clients`}</div>
              : <div key={el.id}>{el.name}</div>
          })
        }
      </div>

    </div>
  )
}

export const TierPage = ({
                    changeTab,
                    setSelectedPlan,
                    selectedPlan,
                    showBackBtn = true,
                  }) => {

  const [inventoryItems, setInventoryItems] = useState(undefined)
  const [inventoryItemFeatures, setInventoryItemFeatures] = useState(undefined)
  const [allFeatures, setAllFeatures] = useState(undefined)

  useEffect(() => {
    const loadData = async () => {
        const {responseCode, responseMessage, data} = await loadSubscriptions()
        if (responseCode === SUCCESSFUL_CODE) {
        ReactDOM.unstable_batchedUpdates(() => {
          setInventoryItems(data.inventoryItems)
          setAllFeatures(data.features)
          setInventoryItemFeatures(data.inventoryItemFeatures)
        })
      } else {
        console.error(responseMessage)
      }
    }

    loadData().then()
  }, [setInventoryItems])

  return (
    <div className="panel-plans-container">

      <div className='panel-plans-body'>
        {
          inventoryItems && inventoryItems.map(plan => {
            const featureNamesList = allFeatures.map(feature => {
              const searchElement = find(inventoryItemFeatures, {inventoryItemId: plan.id, featureId: feature.id})

              if (!!searchElement) {
                  return ({
                    id: feature.id,
                    name: feature.name
                  })
              } else {
                return null
              }
            }).filter(Boolean)

            return (
              <PlanContainer
                key={plan.id}
                plan={plan}
                featureNamesList={featureNamesList}
                handleClickOnSelectedLink={() => setSelectedPlan(plan)}
                isSelectedPlan={get(selectedPlan, ['id'], undefined) === plan.id}
              />
            )
          })
        }
      </div>

      <div className='container-plan-buttons'>
        {
          showBackBtn
          && <button
            className="back"
            onClick={() => changeTab(TAB_SUPER_ADMIN, null)}
          >
            <ReactSVG
              src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
              className="img-back"
            />
            {'Back'}
          </button>
        }
        <button
          className={selectedPlan ? 'next' : 'next-disabled'}
          onClick={() => changeTab(TAB_NEW_DEALER_PAYMENT_INFO, null)}
          disabled={!selectedPlan}
        >
          Next
        </button>
      </div>

    </div>
  )
}

export const PaymentMethod = ({ onClick, selectedPlan, onBack = null, errorMessage = null,
                                requiredElements = true, showBackBtn = true,
                                introMessage = 'This info will be saved for billing later',
                                classCardNumber = 'form-group col-md-6',
                                classExpDate = 'form-group col-md-3',
                                classCVV = 'form-group col-md-3',
                                classBillingAddress = 'form-group col-md-10', customPayMessage = null,
                                classZipCode = 'form-group col-md-2', showCheckbox = false,
                                checkBoxMessage = '', showSkipBtn = false,
                                accessFrom = ACCESS.ADMIN,
                                classCardHolder = 'form-group col-md-12'
}) => {
  const [instance, setInstance] = useState(null)
  const [deviceData, setDeviceData] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessingSkip, setIsProcessingSkip] = useState(false)

  const [config, setConfig] = useState()
  const [isLoaded, setLoaded] = useState(false)
  const [isCheckedOnForm, setIsCheckedOnForm] = useState(false)
  const [errors, setErrors] = useState([])
  const [errorPaymentMessage, setErrorPaymentMessage] = useState('')

  const loadBraintreeScript = async () => {
    await loadScripts(
      [
        'https://js.braintreegateway.com/web/3.81.0/js/client.min.js',
        'https://js.braintreegateway.com/web/3.81.0/js/hosted-fields.min.js',
        'https://js.braintreegateway.com/web/3.81.0/js/data-collector.min.js'
      ]
    )

    setLoaded(true)
  }

  useEffect(() => {
   async function initialize() {
      const {responseCode, responseMessage, data} = (accessFrom === ACCESS.PUBLIC)
        ? await publicInitializePaymentProcessor()
        : await initializePaymentProcessor()
      if (responseCode === SUCCESSFUL_CODE) {
        setConfig(data)
      } else {
        console.error(responseMessage)
      }
    }

    initialize().then()
  }, [])

  useEffect(() => {
    loadBraintreeScript().then()

    return () => cleanUpBraintree(instance)
  }, [])

  const initializeBraintreeInstance = async () => {
    // const token = (await clientToken()).clientToken
    const token = config.clientToken
    const defaultFields = {
      cardholderName: {
        container: '#card-holder',
        placeholder: 'Card holder'
      },
      number: {
        container: '#card-number',
        placeholder: '4111 1111 1111 1111'
      },
      cvv: {
        container: '#cvv',
        placeholder: '123',
        type: 'password'
      },
      expirationDate: {
        // container: '#expiration-date',
        container: '#exp-date',
        placeholder: '10/2022'
      },
      postalCode: {
        container: '#postal-code',
        placeholder: '11111'
      }
    }
    const publicAccessFields = {
      cardholderName: {
        container: '#card-holder',
        placeholder: 'As it appears on your credit card'
      },
      number: {
        container: '#card-number',
        placeholder: '0000 0000 0000 0000'
      },
      cvv: {
        container: '#cvv',
        placeholder: '000',
        type: 'password'
      },
      expirationDate: {
        container: '#exp-date',
        placeholder: '01/12'
      },
      postalCode: {
        container: '#postal-code',
        placeholder: '00000'
      }
    }
    const defaultStyles = {
      'input': {
        'font-size': '14px'
      },
      'input.invalid': {
        'color': 'red'
      },
      'input.valid': {
        'color': 'green'
      },
      '::-webkit-input-placeholder': {
        'color': '#ccc'
      },
      ':-moz-placeholder': {
        'color': '#ccc'
      },
      '::-moz-placeholder': {
        'color': '#ccc'
      },
      ':-ms-input-placeholder': {
        'color': '#ccc'
      },
    }
    const publicAccessStyles = {
      'input': {
        'font-size': '14px'
      },
      'input.invalid': {
        'color': 'red'
      },
      'input.valid': {
        'color': '#555'
      },
      '::-webkit-input-placeholder': {
        'color': '#ccc'
      },
      ':-moz-placeholder': {
        'color': '#ccc'
      },
      '::-moz-placeholder': {
        'color': '#ccc'
      },
      ':-ms-input-placeholder': {
        'color': '#ccc'
      },
    }

    try {
      const clientInstance = await braintree.client.create({
        // Insert your tokenization key here
        authorization: token
      })

      const instance = await braintree.hostedFields.create({
        client: clientInstance,
        // Customize the Hosted Fields.
        // More information can be found at:
        // https://developers.braintreepayments.com/guides/hosted-fields/styling/javascript/v3
        styles: accessFrom === ACCESS.PUBLIC ? publicAccessStyles : defaultStyles,
        // Configure which fields in your card form will be generated by Hosted Fields instead
        fields: accessFrom === ACCESS.PUBLIC ? publicAccessFields : defaultFields
      })

      const dataCollectorInstance = await braintree.dataCollector.create({client: clientInstance})

      ReactDOM.unstable_batchedUpdates(() => {
        setDeviceData(dataCollectorInstance.deviceData)
        setInstance(instance)
      })

    } catch (error) {
      console.error('braintree:', error)
    }
  }

  const handleProcessOrder = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    if (!instance)
      return

    setIsProcessing(true)
    //{billingAddress: {streetAddress: 'Av. manuel villar 269'}}
    instance.tokenize(getBillAddress(),async (tokenizeErr, payload) => {
      if (tokenizeErr) {
        console.error(tokenizeErr)
        await onClick(null, selectedPlan, requiredElements, isCheckedOnForm)
        setIsProcessing(false)
        return
      }

      await onClick(payload.nonce, selectedPlan, requiredElements, isCheckedOnForm)

      instance.teardown(function (teardownErr) {
        if (teardownErr) {
          console.error('Could not tear down the Hosted Fields form!')
          setIsProcessing(false)
        } else {
          console.info('Hosted Fields form has been torn down!')
          ReactDOM.unstable_batchedUpdates(() => {
            // Remove the 'Submit payment' button
            setInstance(null)
            setIsProcessing(false)
          })
        }
      })

    })
  }

  useEffect(() => {
    if(!instance && config && isLoaded && !isProcessing){
      initializeBraintreeInstance().then()
    }
  }, [config, isLoaded, isProcessing])

  if (!isLoaded)
    return null

  const handleSkipAndCreate = async () => {
    setIsProcessingSkip(true)
    await onClick(null, selectedPlan, false, isCheckedOnForm)
    setIsProcessingSkip(false)
    return
  }

  const handleProcessOrderForPublicAccess = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!instance)
      return

    const infoBillAddress = getBillAddress()
    const isEmptyBillingAddress = isEmpty(infoBillAddress.billingAddress.streetAddress)

    if (isEmptyBillingAddress) {
      ReactDOM.unstable_batchedUpdates(() => {
        setErrors(['streetAddress'])
        setErrorPaymentMessage('Field is empty.')
      })
      return
    }

    setIsProcessing(true)
    instance.tokenize(infoBillAddress, async (tokenizeErr, payload) => {
      if (tokenizeErr) {
        switch (tokenizeErr.code) {
          case 'HOSTED_FIELDS_FIELDS_EMPTY':
            setErrors(isEmptyBillingAddress
              ? ['number', 'cvv', 'expirationDate', 'postalCode', 'cardholderName', 'streetAddress']
              : ['number', 'cvv', 'expirationDate', 'postalCode', 'cardholderName']
            )
            setErrorPaymentMessage('Field is empty.')
            break;
          case 'HOSTED_FIELDS_FIELDS_INVALID':
            const invalidFields = tokenizeErr.details.invalidFieldKeys
            setErrors(isEmptyBillingAddress
              ? invalidFields.concat(['streetAddress'])
              : invalidFields
            )
            setErrorPaymentMessage('Invalid field')
            break;
          default:
            console.error('Something bad happened!', tokenizeErr);
        }

        // console.error(tokenizeErr)
        // await onClick(null, selectedPlan, requiredElements, isCheckedOnForm)
        setIsProcessing(false)
        return
      }

      setErrors([])
      setErrorPaymentMessage('')

      await onClick(payload.nonce, selectedPlan, requiredElements, isCheckedOnForm)

      instance.teardown(function (teardownErr) {
        if (teardownErr) {
          console.error('Could not tear down the Hosted Fields form!')
          setIsProcessing(false)
        } else {
          console.info('Hosted Fields form has been torn down!')
          ReactDOM.unstable_batchedUpdates(() => {
            // Remove the 'Submit payment' button
            setInstance(null)
            setIsProcessing(false)
          })
        }
      })

    })
  }

  const getClassNameForBraintreeInput = (id) => {
    return errors.includes(id) ? 'custom-invalid-payment-input' : 'custom-payment-input'
  }

  const onChangeBillingAddress = (event) => {
    if (event.target.value) {
      ReactDOM.unstable_batchedUpdates(() => {
        setErrors([])
        setErrorPaymentMessage('')
      })
    } else {
      ReactDOM.unstable_batchedUpdates(() => {
        setErrors(['streetAddress'])
        setErrorPaymentMessage('Field is empty.')
      })
    }
  }

  if (accessFrom === ACCESS.PUBLIC) {
    return (
      <form onSubmit={handleProcessOrderForPublicAccess} className={'public-form-payment-method'}>
        <div className={'main-message-box'}>
          <ReactSVG src={pathServer.PATH_IMG + "icon/bookmark_star.svg"}
                    className={"icon-main-message-box"}
                    beforeInjection={svg => svg.classList.add("icon-main-message-box")}/>
          <div className={'content-main-message-box'}>
            <div className={'title'}>Let’s get started for free</div>
            <div className={'subtitle'}>You won’t be charged now. Your plan starts after the 14-day trial.</div>
          </div>
        </div>

        {
          errorMessage && <div className={'error-message-tab'}>{errorMessage}</div>
        }

        <div className="form-row">
          <div className={classCardHolder}>
            <label htmlFor="card-holder">Card holder name</label>
            <span className="label-error">*</span>
            <div className={getClassNameForBraintreeInput('cardholderName')} id="card-holder"/>
            {errors.includes('cardholderName') && <span className={'error-message'}>{errorPaymentMessage}</span>}

          </div>
        </div>
        <div className="form-row">
          <div className={classCardNumber}>
            <label htmlFor="card-number">Card number</label>
            <span className="label-error">*</span>
            <div className={getClassNameForBraintreeInput('number')} id="card-number"/>
            {errors.includes('number') && <span className={'error-message'}>{errorPaymentMessage}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={classExpDate}>
            <label htmlFor="exp-date">Expiration date</label>
            <span className="label-error">*</span>
            <div className={getClassNameForBraintreeInput('expirationDate')} id="exp-date"/>
            {errors.includes('expirationDate') && <span className={'error-message'}>{errorPaymentMessage}</span>}
          </div>
          <div className={classCVV}>
            <label htmlFor="cvv">CVV</label>
            <span className="label-error">*</span>
            <div className={getClassNameForBraintreeInput('cvv')} id="cvv"/>
            {errors.includes('cvv') && <span className={'error-message'}>{errorPaymentMessage}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={classBillingAddress}>
            <label htmlFor="address">Billing address</label>
            <span className="label-error">*</span>
            <input type="text"
                   className={errors.includes('streetAddress') ? 'form-control error-field' : 'form-control'}
                   id="streetAddress" name="streetAddress"
                   placeholder={'Type your address'}
                   onChange={onChangeBillingAddress}/>
            {errors.includes('streetAddress') && <span className={'error-message'}>{errorPaymentMessage}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={classZipCode}>
            <label htmlFor="zip">Zip Code</label>
            <span className="label-error">*</span>
            <div className={getClassNameForBraintreeInput('postalCode')} id="postal-code"/>
            {errors.includes('postalCode') && <span className={'error-message'}>{errorPaymentMessage}</span>}
          </div>
        </div>
        <div className="form-row">
          <DPButton width="100"
                    height="80"
                    className={(!isLoaded || isProcessing) ? 'disabled-button' : "submit-button"}
                    disabled={!isLoaded || isProcessing}
                    onClick={isProcessing ? null : onClick}
          >
            {isProcessing ? 'Processing...' : 'Create account'}
          </DPButton>
        </div>
      </form>
    )
  }

  return (
    <div className="panel-payment-method-container">
      {
        errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>
      }
      {
        introMessage && <div><span className={'intro-message'}>This info will be saved for billing later</span></div>
      }
      <PaymentModalForm
        paymentHandler={handleProcessOrder}
        isDisabled={isProcessing || isProcessingSkip}
        isProcessing={isProcessing}
        showBackBtn={showBackBtn}
        handleClickOnBackBtn={onBack}
        textForSubmitBth={`${customPayMessage ? customPayMessage : 'Save'}`}
        requiredElements={requiredElements}
        classCardNumber={classCardNumber}
        classExpDate={classExpDate}
        classCVV={classCVV}
        classBillingAddress={classBillingAddress}
        classZipCode={classZipCode}
        showCheckbox={showCheckbox}
        checkBoxMessage={checkBoxMessage}
        isCheckedOnForm={isCheckedOnForm}
        handleClickOnCheckBox={setIsCheckedOnForm}
        skipHandler={handleSkipAndCreate}
        showSkipBtn={showSkipBtn}
        isProcessingSkip={isProcessingSkip}
      />
    </div>
  )
}

const InputValue = ({
                      placeholder, name, onChange, onBlur, errorMessage, errorEmail, title, isRequired,
                      value, type, errorDuplicate, domain, maxLength, dataTestId
                    }) => {
  const error = {
    message: errorMessage,
    email: errorEmail,
    duplicate: (errorDuplicate && errorDuplicate[name]) ? errorDuplicate[name] : null
  }
  const messageDuplicate = (errorDuplicate && errorDuplicate[name]) ? errorDuplicate[name] : null
  return (
    <div className="flex-colR1">
      <div className="item">
        <div>
          <span>{title}</span>
          {isRequired ? <span className="label-error">*</span> : null}
        </div>
        <div className="container-input">
          <input
            id={"input" + name}
            data-testid={dataTestId}
            type="text" className="form-control" placeholder={placeholder}
            style={getStyle(name, messageDuplicate, value)}
            onChange={(e) => onChange(name, type, e)}
            value={value ? value : ""}
            maxLength={maxLength}
            onBlur={onBlur ? (e) => onBlur(name, isRequired, type, e) : () => {
            }}/>
          {
            <Error dataTestId={'error-label'} error={error}/>
          }
          {name === DOMAIN_URL_KEY ?
            <span id="domainSpan" className="domain">{`.${domain}`}</span> : null
          }
        </div>
      </div>
    </div>
  )
}

const InputMask = ({placeholder, name, onChange, onBlur, errorMessage, title, isRequired, value, type, dataTestId}) => {
  return (
    <div className="flex-colR1">
      <div className="item">
        <div>
          <span>{title}</span>
          {isRequired ? <span className="label-error">*</span> : null}
        </div>
        <InputElement type="text" className="form-control"
                      mask="(999)999-9999"
                      placeholder={placeholder}
                      alwaysShowMask={false}
                      maskChar=""
                      name="phoneNumber" readOnly={false}
                      onChange={(e) => onChange(name, type, e)}
                      onBlur={onBlur ? (e) => onBlur(name, isRequired, type, e) : () => {
                      }}
                      data-testid={dataTestId}
                      value={value ? value : null}/>
        {(errorMessage) ? <span className="error-message">Fill the field correctly.</span> : null}
      </div>
    </div>
  )
}

function getAccountOwnerInitial() {
  return {
    firstName: "", lastName: "", phoneNumber: "", email: "", address: "", description: "",
    image: "", active: true, verify: false
  }
}

function getDealerInitial() {
  return {
    name: "", dealerNumber: "", status: true, address1: "", address2: "", city: "", state: "", zip: "",
    image: "", active: true, phoneNumber: "", email: "", domainUrl: ""
  }
}

const fieldsRequiredAccountOwner = [{key: "firstName", type: "required"},
  {key: "lastName", type: "required"},
  {key: "email", type: "required"},
  {key: "phoneNumber", type: "required"},
  {key: "active", type: "active"},
  {key: "password", type: "password"}]

function calculatePaddingRight() {
  const element = window.document.getElementById("domainSpan")
  const inputElement = window.document.getElementById("inputdomainUrl")
  if (element && inputElement) {
    const width = element.offsetWidth
    inputElement.style.paddingRight = width + 10 + "px"
  }
}

const Error = ({error, dataTestId}) => {
  let className = ""
  let message
  if (error.message) {
    className = "error-message"
    message = "This field must be filled."
  } else if (error.email) {
    className = "error-message"
    message = "Email is not valid."
  } else if (error.duplicate) {
    className = (error.duplicate === "Available") ? "available-message" : "error-message"
    message = error.duplicate
  } else {
    message = null
  }
  return (
    message ?
      <span className={className} data-testid={dataTestId}>{message}</span> : null
  )
}

function getStyle(name, messageDuplicate, value) {
  if (name === DOMAIN_URL_KEY && value.length >= 3) {
    if (messageDuplicate) {
      return messageDuplicate === "Available" ? {borderColor: "#4AD991"} :
        {borderColor: "#FF6565"}
    } else {
      return {}
    }
  }
}

function getError(errors, object, type) {
  let value
  if (type === TAB_DEALER) {
    fieldsRequiredUpdate.forEach(field => {
      const {key, type} = field
      if (type === "required") {
        value = object[key]
        if (key === "phoneNumber") {
          errors[key] = !checkValidPhone(value)
        } else if (key === "email") {
          errors[key] = isEmpty(value)
          if (!errors[key]) {
            errors[key] = validInputsNotRequired(object[key], checkValidEmail)
          }
        } else {
          errors[key] = isEmpty(value)
        }
      }
    })
  } else {
    fieldsRequiredAccountOwner.forEach(field => {
      const {key, type} = field
      if (type === "required") {
        if (key === "firstName" || key === "lastName" || key === "email") {
          value = object[key]
          errors[key] = isEmpty(value)
        }
      } else {
        errors[key] = false
      }
    })
  }

  return errors
}

class Dealers extends Component {
  constructor() {
    super()
    this.loadDealers = this.loadDealers.bind(this)
    this.verifySubdomainDealer = this.verifySubdomainDealer.bind(this)
    this.verifySubdomainDealerDebounce = debounce(this.verifySubdomainDealer, 500)
    this.changeKeyword = debounce(this.loadDealers,500);
    this.state = {
      showOptions: false,
      itemsByPageQuantity: DEALERS_BY_PAGE_BY_DEFAULT,
      currentPage: 1,
      dealers: [],
      isLoading: true,
      showPopover: false,
      showPopoverSelect: false,
      indexDealerSelected: null,
      indexUserSelected: null,
      isLoadingStatus: false,
      selectStatus: null,
      showModal: false,
      statusOptionSelected: null,
      dealerUpdate: {},
      anchorEl: null,
      file: [],
      statusPopoverValue: "Active",
      statePopoverValue: null,
      errors: {},
      dealerName: null,
      showPopoverDropDown: false,
      selectPopoverDropDown: null,
      query: {},
      quantityDealers: 0,
      showModalAddDealer: false,
      currentTab: TAB_DEALER,
      errorEmail: false,
      newDealer: getDealerInitial(),
      newAccountOwner: getAccountOwnerInitial(),
      fileAccountOwner: [],
      errorsAccountOwner: {},
      errorEmailAccountOwner: false,
      isLoadingModal: false,
      errorDuplicate: {},
      fileDealerIcon: [],
      errorMessage: null,
      keyword: null,
    }
  }

  componentDidMount() {
    this.loadDealers()
  }

  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.keyword !== state.keyword) {
      return {
        keyword: props.keyword,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidUpdate(prevProps, prevState){
    const {keyword, isLoading} = this.state
    if (prevProps.keyword !== keyword && isLoading === false) {
      this.setState({isLoading: true})
      this.changeKeyword();
    }
  }

  renderDropDownFilter(evt) {
    const {showPopoverSelect} = this.state
    const target = evt.currentTarget
    const anchorEl = getAnchorEl(target, 2)
    this.setState({showPopoverSelect: !showPopoverSelect, anchorEl: anchorEl})
  }

  loadDealers() {
    const {itemsByPageQuantity,keyword} = this.state
    loadAllDealers(0, itemsByPageQuantity,keyword).then(response => {
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const {dealers, quantityDealers, domain} = data
        const domainUrl = domain.replace("www.", "")
        this.setState({dealers: dealers, quantityDealers, isLoading: false, domain: domainUrl, currentPage: 1})
      }else{
        this.setState({isLoading: false})
      }
    })
  }

  showPopoverStatus = (index) => {
    // evt.stopPropagation();
    const {showPopover, dealers} = this.state
    // const target = evt.currentTarget;
    // const anchorEl = getAnchorEl(target, 2);
    const selectStatus = dealers[index].status
    this.setState({showPopover: !showPopover, indexDealerSelected: index, selectStatus})
  }

  handleRequestOpen(event) {
    this.setState({
      showOptions: true,
      anchorEl: {offsetWidth: event.currentTarget.offsetWidth, offsetHeight: event.currentTarget.offsetHeight}
    })
  }

  handleRequestClose = () => {
    this.setState({showOptions: false, showPopover: false})
  }

  handlePageChange = async(nextPage, totalPages) => {
    let {itemsByPageQuantity, query, quantityDealers, keyword} = this.state
    if (nextPage >= 0) {
      let currentPage = nextPage
      if (currentPage > totalPages) {
        currentPage = 1
        query.from = 0
      } else if (currentPage === 0) {
        currentPage = totalPages
        query.from = itemsByPageQuantity * (totalPages - 1)
      } else {
        if (quantityDealers !== 0)
          query.from = itemsByPageQuantity * (currentPage - 1)
      }
      query.size = itemsByPageQuantity
      this.setState({isLoading: true})

      const {responseCode, responseMessage, data} = await loadAllDealers(query.from, query.size, keyword)
      if (responseCode === SUCCESSFUL_CODE){
        const {dealers, quantityDealers} = data
        this.setState({dealers, quantityDealers, currentPage, isLoading: false})
      }else{
        console.error(responseMessage)
        this.setState({isLoading: false})
      }
    }
  }

  handleItemsByPage = async(itemsByPageQuantity) => {
    this.setState({isLoading: true})
    const {keyword} = this.state

    const {responseCode, responseMessage, data} = await loadAllDealers(query.from, query.size, keyword)
    if (responseCode === SUCCESSFUL_CODE){
      const {dealers, quantityDealers} = data
        this.setState({
          showOptions: false,
          dealers,
          quantityDealers,
          itemsByPageQuantity,
          currentPage: 1,
          isLoading: false
        })
    }else{
      console.error(responseMessage)
      this.setState({isLoading: false})
    }
  }

  selectStatus = (status) => {
    this.setState({showPopover: false, statusOptionSelected: status, showModal: true})
  }

  changeStatus = () => {
    const {dealers, indexDealerSelected, statusOptionSelected} = this.state
    const dealerId = dealers[indexDealerSelected].id
    updateDealerStatus(dealerId, statusOptionSelected).then(response => {
      const {responseCode, responseMessage} = response
      if (responseCode === SUCCESSFUL_CODE){
        this.setState({isLoading: true, showModal: false})
        this.loadDealers()
      }else{
        console.error(responseMessage)
      }
    })
  }

  onHide = () => {
    this.setState({showModal: false})
  }

  goToDealer = (dealerId) => {
    browserHistory.push(`/main/superusers/dealers/edit/${dealerId}`)
  }

  closeModal = () => {
    this.setState({
      showPopoverDropDown: false,
      selectPopoverDropDown: null,
      showModalAddDealer: false,
      newDealer: getDealerInitial(),
      newAccountOwner: getAccountOwnerInitial(),
      statusPopoverValue: null,
      statePopoverValue: null,
      file: [],
      fileAccountOwner: [],
      errors: [],
      errorsAccountOwner: [],
      errorEmailAccountOwner: false,
      errorEmail: false,
      currentTab: TAB_DEALER
    })
  }

  closePopoverSelect = () => {
    this.setState({showPopoverDropDown: false, selectPopoverDropDown: null})
  }

  showPopoverDropDownModal = (id, event) => {
    event.preventDefault()
    const target = event.currentTarget
    let anchorEl = getAnchorEl(target, 2)
    this.setState({anchorEl: anchorEl, showPopoverDropDown: true, selectPopoverDropDown: id})
  }

  onDrop = (uploadFile, type) => {
    let {file, fileAccountOwner} = this.state
    let fileDealerIcon = DEFAULT_VALUE_FILE_DEALER_ICON
    if (type === TAB_DEALER) {
      file = uploadFile
    } else if (type === DEALER_ICON) {
      fileDealerIcon = uploadFile
    } else {
      fileAccountOwner = uploadFile
    }
    this.setState({file: file, fileAccountOwner: fileAccountOwner, fileDealerIcon})
  }

  onChangeValue = (key, type, event) => {
    let {dealerUpdate, newDealer, newAccountOwner, errors, errorsAccountOwner, errorDuplicate} = this.state
    if (type === "update") {
      dealerUpdate[key] = event.target.value
    } else if (type === TAB_DEALER) {
      newDealer[key] = event.target.value
      errors[key] = false
      if (key === "name")
        errorDuplicate[key] = null
      else if (key === DOMAIN_URL_KEY)
        errorDuplicate[key] = null
    } else {
      newAccountOwner[key] = event.target.value
      errorsAccountOwner[key] = false
      if (key === "email")
        errorDuplicate[key] = null
    }

    this.setState({
      dealerUpdate: dealerUpdate,
      newDealer: newDealer,
      newAccountOwner: newAccountOwner,
      errors: errors,
      errorsAccountOwner: errorsAccountOwner
    })
  }

  onChangeValueSubdomain = (key, type, event) => {
    const {newDealer, errors, errorDuplicate} = this.state
    let updateDealer = cloneDeep(newDealer)
    const newValue = event.target.value.replace(/(\s*)(\.*)(\/*)/g, "")
    if (updateDealer.domainUrl !== newValue) {
      updateDealer[key] = newValue
      this.setState({newDealer: updateDealer})

      if (errors.domainUrl)
        errors.domainUrl = false

      if (errorDuplicate.domainUrl)
        errorDuplicate.domainUrl = null

      this.verifySubdomainDealerDebounce(newValue)
    }
  }

  async verifySubdomainDealer(newValue) {
      let {errorDuplicate} = this.state
      if (newValue.length >= 3) {
          const response = await verifySubdomain(newValue)
          const {responseCode, responseMessage, data} = response
          if (responseCode === SUCCESSFUL_CODE) {
              const {isValid, reason} = data
              if (isValid)
                  errorDuplicate.domainUrl = "Available"
              else
                  errorDuplicate.domainUrl = reason

              this.setState({errorDuplicate})
          } else {
              console.error("error ==>", responseMessage)
          }
      } else {
          errorDuplicate.domainUrl = "Subdomain must have at least 3 letters."
          this.setState({errorDuplicate})
      }
  }

  selectOptionPopover = (type, value, displayValue, object) => {
    let {dealerUpdate, newDealer, statusPopoverValue, statePopoverValue} = this.state
    if (object === "new") {
      newDealer[type] = value
    } else {
      dealerUpdate[type] = value
    }
    if (type === "status")
      statusPopoverValue = displayValue
    else
      statePopoverValue = displayValue
    this.setState({
      dealerUpdate: dealerUpdate,
      statusPopoverValue: statusPopoverValue,
      statePopoverValue: statePopoverValue,
      showPopoverDropDown: false,
      selectPopoverDropDown: null,
      newDealer: newDealer
    })
  }

  deleteFile = (type = '') => {
    let {dealerUpdate, fileDealerIcon, file} = this.state
    if (!isEmpty(dealerUpdate["logoUrl"])) {
      dealerUpdate["logoUrl"] = ""
    }

    if (isEmpty(type)) {
      file = DEFAULT_VALUE_FILE_DEALER_LOGO
    }

    if (type === DEALER_ICON) {
      fileDealerIcon = DEFAULT_VALUE_FILE_DEALER_ICON
    }

    this.setState({file, dealerUpdate: dealerUpdate, fileDealerIcon})
  }

  showModalAddDealer = () => {
    this.setState({showModalAddDealer: true, errorFromBD: false}, () => {
      calculatePaddingRight()
    })
  }

  changeTab = (tab, entity = null) => {
    const {errors} = this.state
    if (tab === TAB_SUPER_ADMIN && entity != null) {
      const error = getError(errors, entity, TAB_DEALER)
      const hasError = validateError(error)
      if (!hasError) {
        this.setState({currentTab: tab})
      } else {
        this.setState({errors: error})
      }
    } else if (tab === TAB_NEW_DEALER_TIER && entity != null) {
      const error = getError(errors, entity, TAB_SUPER_ADMIN)
      const hasError = validateError(error)
      if (!hasError) {
        this.setState({currentTab: tab})
      } else {
        this.setState({errorsAccountOwner: error})
      }
    }
    else {
      this.setState({currentTab: tab})
    }
  }

  onBlur = (key, isRequired, type, e) => {
    let value = e.target.value
    let {errors, errorsAccountOwner, errorDuplicate, newDealer} = this.state
    if (isRequired) {
      if (type === TAB_DEALER) {
        errors[key] = isEmpty(value)
      } else {
        errorsAccountOwner[key] = isEmpty(value)
      }
      if (key === "email") {
        if (!checkValidEmail(value)) {
          if (type === TAB_DEALER) {
            this.setState({errorEmail: true})
          } else {
            this.setState({errorEmailAccountOwner: true})
          }
        } else {
          if (type === TAB_DEALER) {
            this.setState({errorEmail: false})
          } else {
            this.setState({errorEmailAccountOwner: false})
          }
        }
      }
    } else {
      if (key === "phoneNumber") {
        if (type === TAB_DEALER) {
          errors[key] = !checkValidPhone(value)
        } else {
          errorsAccountOwner[key] = !checkValidPhone(value)
        }
        if (value === "") {
          if (type === TAB_DEALER) {
            errors[key] = false
          } else {
            errorsAccountOwner[key] = false
          }
        }
      }
    }

    this.setState({errors: errors, errorsAccountOwner: errorsAccountOwner})
  }

  save = async (payloadNonce = null, selectedPlan = null, requiredElements = true) => {
    if (payloadNonce === null && requiredElements) {
      this.setState({errorMessage: 'Some fields are empty. Please complete all.'})
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, TIME_FIVE_SECONDS)
      return
    }

    const {newDealer, newAccountOwner, file, fileAccountOwner, errors, errorsAccountOwner, fileDealerIcon} = this.state
    let {errorDuplicate} = this.state
    errorDuplicate = {}
    const error = getError(errors, newDealer, TAB_DEALER)
    const hasError = validateError(error)
    const errorAccountOwner = getError(errorsAccountOwner, newAccountOwner, TAB_SUPER_ADMIN)
    const hasErrorAccountOwner = validateError(errorAccountOwner)

    const currentDate = Moment()

    let orderItems = null
    if (selectedPlan) {
      orderItems = [{
        inventoryItemId: selectedPlan.id,
        quantity: 1,
        unitPrice: selectedPlan.price,
        status: 'unpaid'
      }]
    }

    if (!hasError && !hasErrorAccountOwner) {
      const body = {
        fileDealer: file,
        dealer: newDealer,
        fileAccountOwner: fileAccountOwner,
        accountOwner: newAccountOwner,
        fileDealerIcon,
        orderPayment: {
          order: {
            startDate: Moment(getNewDate(currentDate)).valueOf(),
            endDate: Moment(getNewDate(currentDate)).add(30, 'days').valueOf(),
            dueDate: Moment(getNewDate(currentDate)).add(40, 'days').valueOf(),
            taxAmount: 0,
            status: 'unpaid',
            dealerId: null,
            orderItems: orderItems,
          },
          nonce: payloadNonce,
        }
      }
      this.setState({isLoadingModal: true})
        const response = await saveNewDealerAndAccountOwner(body)
        const {responseCode, responseMessage, data} = response
        if (responseCode === SUCCESSFUL_CODE) {
            this.setState({
                isLoadingModal: false,
                showModalAddDealer: false,
                newDealer: getDealerInitial(),
                newAccountOwner: getAccountOwnerInitial(),
                errorEmail: false,
                errors: {},
                file: [],
                fileAccountOwner: [],
                errorEmailAccountOwner: false,
                errorsAccountOwner: {},
                currentTab: TAB_DEALER,
                isLoading: true,
                errorDuplicate
            }, () => {
                this.loadDealers()
            })
        }
        else {
            let currentTab = TAB_NEW_DEALER_PAYMENT_INFO

            if (data.code === ERROR_DUPLICATE_CODE) {
                if (data.field === DOMAIN_URL_KEY) {
                    errorDuplicate[data.field] = 'This subdomain is already taken or does not have at least 3 letters.'
                    currentTab = TAB_FOR_DUPLICATE_FIELDS.DOMAIN_URL_KEY
                }
                else if (data.field === DUPLICATE_FIELDS.NAME) {
                    errorDuplicate[data.field] = responseMessage
                    currentTab = TAB_FOR_DUPLICATE_FIELDS.NAME
                }
                else if (data.field === DUPLICATE_FIELDS.EMAIL) {
                    errorDuplicate[data.field] = responseMessage
                    currentTab = TAB_FOR_DUPLICATE_FIELDS.EMAIL
                }
                else
                    errorDuplicate[data.field] = responseMessage
            }

            this.setState({isLoading: false, isLoadingModal: false, errorDuplicate, errorFromBD: responseMessage, currentTab})
            setTimeout(function () {
                this.setState({isLoading: false, isLoadingModal: false, errorFromBD: ""})
            }.bind(this), TIME_FIVE_SECONDS)
        }
    } else {
      this.setState({errors: error})
    }
  }

  goToPublicPage(evt) {
    evt.stopPropagation()
    const publicPage = evt.currentTarget.id
    window.open(`//${publicPage}/catalog`, '_blank')
  }

  render() {
    // const {height, left} = this.props
    const {
            anchorEl,
            itemsByPageQuantity,
            currentPage,
            dealers,
            isLoading,
            indexDealerSelected,
            showModal,
            file,
            statusPopoverValue,
            statePopoverValue,
            errors,
            statusOptionSelected,
            showPopoverDropDown,
            selectPopoverDropDown,
            quantityDealers,
            showModalAddDealer,
            currentTab,
            errorEmail,
            fileAccountOwner,
            newDealer,
            newAccountOwner,
            errorsAccountOwner,
            errorEmailAccountOwner,
            isLoadingModal,
            errorFromBD,
            domain,
            errorDuplicate,
            fileDealerIcon,
            errorMessage
          } = this.state
    const totalPages = quantityDealers ? Math.ceil(quantityDealers / itemsByPageQuantity) : 1
    return (
      <>
        <DPPage
          className="dealers-page"
          title="Users"
          leftHeader={
            <>
              <DPSelectionDropDown
                style={{width: 160}}
                placeholder={'All Dealers'}
                id={'superusers-sort-by'}
                options={[]}
                value={''}
              />
              <div className="dealer-button-add">
                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_circle.svg"}
                          beforeInjection={svg => svg.classList.add("icon-add-svg")}
                          data-testid={'icon-add-new-dealer'}
                          className="icon-add-dealer" onClick={this.showModalAddDealer}/>
              </div>

            </>
          }
          rightHeader={
            <DPPagination
              optionsQuantityByPage={DEALERS_BY_PAGE}
              selectedItemsByPage={itemsByPageQuantity}
              handleChangeItemsByPage={this.handleItemsByPage}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={this.handlePageChange}
              siteColor={siteColorAdmin}
            />

          }
        >
          <DPTable
            isLoading={isLoading}
            data={dealers}
            config={
              {
                columns: [
                  {id: 'logo', label: 'Logo', style: {width: '10%'}},
                  {id: 'name', label: 'Dealer Name', style: {width: '15%'}},
                  {id: 'address', label: 'Address', style: {width: '25%'}},
                  {id: 'phoneNumber', label: 'Phone Number', style: {width: '15%'}},
                  {id: 'dealerNumber', label: 'Dealer Number', style: {width: '15%'}},
                  {id: 'publicPage', label: 'Public Page', style: {width: '22%'}},
                  {id: 'status', label: 'Status', style: {width: '15%'}},
                  {id: 'actions', style: {width: '8%', minWidth: 88}}
                ]
              }
            }
            onRowClick={dealer => this.goToDealer(dealer.id)}
            renderColumn={
              (dealer, columnConf, rowIndex) => {
                const {logoUrl, name, address1, phoneNumber, status, domainUrl, dealerNumber} = dealer
                switch (columnConf.id) {
                  case 'logo':
                    return (
                      <DPTableLogoImage logoUrl={logoUrl}/>
                    )
                  case 'name':
                    return name
                  case 'address':
                    return address1
                  case 'phoneNumber':
                    return phoneNumber
                  case 'dealerNumber':
                    return dealerNumber
                  case 'publicPage':
                    return (
                      <a id={`${domainUrl}.${domain}`}
                         onClick={this.goToPublicPage}>{`${domainUrl}.${domain}`}
                      </a>
                    )
                  case 'status':
                    return <DPSelectionDropDown
                      style={{width: 120}}
                      textStyle={{color: status ? '#4AD991' : '#FF6565'}}
                      placeholder={''}
                      id={'dealer-status-dropdown'}
                      options={[{id: 'active', displayValue: 'Active'}, {id: 'inactive', displayValue: 'Inactive'}]}
                      value={status ? 'active' : 'inactive'}
                      onClick={
                        selectedStatus =>
                          this.setState({
                            indexDealerSelected: rowIndex,
                            selectStatus: status === 'active',
                            statusOptionSelected: selectedStatus === 'active',
                            showModal: true
                          })
                      }
                    />

                  case 'actions':
                    return (
                      <DPTableActions>
                          <span>
                            <img key={0 + 'view'} id="imgView"
                                 src={pathServer.PATH_IMG + "icon/ic_television_screen.svg"}
                                 style={{width: "18px"}}
                                 onClick={() => this.goToDealer(dealer.id)}/>
                          </span>
                        <span>
                            <img key={0 + 'trash'} id="imgTrash"
                                 src={pathServer.PATH_IMG + "deleteCustomer.png"}
                                 style={{width: "18px", marginLeft: "13px"}}/>
                          </span>

                      </DPTableActions>
                    )
                }
              }
            }
          />
        </DPPage>
        {
          showModal ?
            <GenericModalConfirm show={showModal}
                                 showCustomIcon={true}

            >
              <Body active={statusOptionSelected} onHide={this.onHide}
                    changeStatus={this.changeStatus}
                    dealerName={dealers[indexDealerSelected].name}/>
            </GenericModalConfirm> : null
        }
        {showModalAddDealer
          ? <ModalAddDealer showModal={showModalAddDealer} onHide={this.closeModal} currentTab={currentTab} file={file}
                            onDrop={this.onDrop}
                            onBlur={this.onBlur} errorEmail={errorEmail} errors={errors} onChange={this.onChangeValue}
                            changeTab={this.changeTab} statusPopoverValue={statusPopoverValue}
                            statePopoverValue={statePopoverValue}
                            showPopoverDropDown={showPopoverDropDown} selectPopoverDropDown={selectPopoverDropDown}
                            selectOptionPopover={this.selectOptionPopover}
                            showPopoverDropDownModal={this.showPopoverDropDownModal}
                            closePopoverSelect={this.closePopoverSelect} anchorEl={anchorEl}
                            fileAccountOwner={fileAccountOwner} dealer={newDealer} accountOwner={newAccountOwner}
                            onClick={(payloadNonce, selectedPlan, requiredElements) => this.save(payloadNonce, selectedPlan, requiredElements)}
                            errorsAccountOwner={errorsAccountOwner}
                            dataTestId="modal-add-new-dealer"
                            errorEmailAccountOwner={errorEmailAccountOwner} isLoading={isLoadingModal}
                            errorFromBD={errorFromBD}
                            errorDuplicate={errorDuplicate}
                            onChangeValueSubdomain={this.onChangeValueSubdomain} domain={domain}
                            deleteFile={this.deleteFile}
                            fileDealerIcon={fileDealerIcon}
                            errorMessage={errorMessage}
          />
          : null
        }
      </>
    )
  }
}

export default Dealers
