import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import React, {useEffect, useRef, useState} from 'react'
import NavigationBar from "./navigationBar/NavigationBar";
import {ReactSVG} from "react-svg";
import DPIcon, {Icons} from "../../../common/DPIcon";
import GDGButton, {BUTTON_TYPE} from "./GDGButton";
import {browserHistory} from "react-router";
import ContactUs from './contactUs/ContactUs'
import { Modal } from 'react-bootstrap'
import {publicLoadSubscriptions} from "../../../../catalog/Api";
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import {unstable_batchedUpdates} from "react-dom";

require('./Pricing.scss')


const checkIcon = <DPIcon icon={Icons.CHECK}/>

const TitleComponent = ({title, subtitle1, subtitle2, setShowModal, setModalBody}) => {

  const showContactUsModal = () => {
    setModalBody(<ContactUs isModal={true}/>)
    setShowModal(true)
  }

  return (
    <div className="title-component">
      <label className="title">{title}</label>
      {
        subtitle1 &&
        <label className="subtitle1">{subtitle1}</label>
      }
      {
        subtitle2 &&
        <label onClick={showContactUsModal}
               className="subtitle2">{subtitle2}</label>
      }
    </div>
  )
};

const deployButtonList = [
  {id:'1'},
  {id:'2', plan: '0-15' },
  {id:'3', plan: '16-40'},
  {id:'4', plan: '41-70'},
]

const TierItem = (props) => {
  return (
    <div className='tier-item'>
      <ReactSVG
        src={`${pathServer.PATH_IMG}icon/ic_right_arrow.svg`}
        className='right-svg-image-container'
        beforeInjection={svg => svg.classList.add('right-icon-svg')}
      />
      <div className='tier-item-text'>
        {props.text}
      </div>
    </div>
  )
}

const TierComponent = ({tier, selectPlan, recommended, setShowModal, setModalBody }) => {
  const {title, subtitle, price, text1, text2, plan} = tier

  return(
    <div className="tier-container" >
      <div className="tier" >
        {recommended && <div className='recommended-section'>RECOMMENDED</div>}
        <div className='main-tier-section'>
          <label className="tier-title">{title}</label>
          <div className='tier-subtitle'>{subtitle}</div>
          <div className="price-section">
            <div className="tier-price">${price}</div>
            <div className="period">/ month</div>
          </div>
          <div className='free-trial-btn-container'>
            <GDGButton
              onClick={() => browserHistory.push({
                pathname: '/marketing/accountCreation',
                state: {selectedPlan: tier}
              })}
              hoverText={'Start free trial'}
              text={'Start free trial'}
              classNames={recommended ? "free-trial-recommended-btn" : "free-trial-btn"}
            />
          </div>
        </div>
        <div className="description-section" >
          <div><b>Includes</b></div>
          <TierItem text={`${text1} listings`}></TierItem>
          <TierItem text={`${text2} users`}></TierItem>
          <TierItem text={`${plan} task on dashboard`}></TierItem>
        </div>
      </div>
    </div>
  )
}


const Pricing = () => {

  const [isChecked, setIsChecked] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState(<></>)
  const [deployTiersList, setDeployTiersList] = useState([])
  const [deployHeadList, setDeployHeadList] = useState([])
  const [deployBodyList, setDeployBodyList] = useState([])
  const [isRendered, setIsRendered] = useState(false)

  const deployBodyList2 = [
    {id:'1', title:'Team members', tier1:'3', tier2:'10', tier3:'Unlimited' },
    {id:'2', title:'Listings', tier1:'Until 500', tier2:'Until 2,000', tier3:'Unlimited'},
    {id:'3', title:'Task Management', tier1:'1,000', tier2:'10,000', tier3:'Unlimited'},
    {id:'4', title: <TitleComponent title={"Features"}/> , tier1:'', tier2:'', tier3:'' },
    {id:'5', title:'Inventory Management', tier1:<>{checkIcon} Included</>, tier2:<>{checkIcon} Included</>, tier3:<>{checkIcon} Included</> },
    {id:'6', title:'Websites', tier1:<>{checkIcon} Included</>, tier2:<>{checkIcon} Included</>, tier3:<>{checkIcon} Included</> },
    {id:'7', title:'Sales', tier1:<>{checkIcon} Included</>, tier2:<>{checkIcon} Included</>, tier3:<>{checkIcon} Included</> },
    {id:'8', title: <TitleComponent title={"Individual features"} subtitle1={"Just needing specifics features?"} subtitle2={"Contact us"} setModalBody={setModalBody} setShowModal={setShowModal}/>, tier1:'', tier2:'', tier3:''},
    {id:'9', title:'CRM', tier1:'500 Clients', tier2:'2,000 Clients', tier3:'Unlimited' },
    {id:'10', title:'Messaging', tier1:'-', tier2:<>{checkIcon} Included</>, tier3:<>{checkIcon} Included</> },
    {id:'11', title:'Reviews', tier1:'-', tier2:<>{checkIcon} Included</>, tier3:<>{checkIcon} Included</> },
  ]

  const navigationBar = useRef();

  const selectPlan = (plan) => {
    if(navigationBar && navigationBar.current)
      navigationBar.current.handleRequestDemo(plan)
  }

  useEffect(() => {
    async function initialize() {
      const {responseCode, responseMessage, data} = await publicLoadSubscriptions()
      if (responseCode === SUCCESSFUL_CODE) {
        unstable_batchedUpdates(() => {
          setDeployTiersList(generateDeployTiersList(data.inventoryItems))
          setDeployHeadList(generateDeployHeadList(data.inventoryItems))
          setDeployBodyList(generateDeployBodyList(data))
          setIsRendered(true)
        })
      } else {
        console.error(responseMessage)
      }
    }

    initialize().then()
  }, [])

  const generateDeployHeadList = (info) => {
    const headList = [{id:'0', text:'Limits'}]
    return headList.concat(info.map(el => ({
      id: el.id,
      text: el.name,
      text2: `$${el.price}/month`
    })))
  }

  const generateDeployTiersList = (info) => {
    const sortedInfo = sortBy(info, ['id'])
    return sortedInfo.map(el => {
      const formatter = new Intl.NumberFormat('en-US')
      return ({
          id: el.id,
          title: el.name,
          price: el.price.toString(),
          text1: el.listingsQuantity === -1
            ? `Unlimited`
            : `Up to ${formatter.format(el.listingsQuantity)}`,
          text2: el.teamMembersQuantity === -1
            ? `Unlimited`
            : `Up to ${formatter.format(el.teamMembersQuantity)}`,
          plan: el.tasksQuantity === -1
            ? `Unlimited`
            : `Up to ${formatter.format(el.tasksQuantity)}`,
          subtitle: el.description
        })
      }
    )
  }

  const generateDeployBodyList = (info) => {
    const getNewKeysForObjectRow = (row, keyToSearch = '', preText = '') => {
      info.inventoryItems.forEach((el, index) => {
        const formatter = new Intl.NumberFormat('en-US')
        row[`tier${index + 1}`] = keyToSearch === ''
          ? ''
          : (el[keyToSearch] === -1
            ? `Unlimited`
            : `${preText}${formatter.format(el[keyToSearch])}`)
      })
    }

    // Getting Team members row
    let teamMembersRow = {id: '1', title: 'Team members'}
    getNewKeysForObjectRow(teamMembersRow, 'teamMembersQuantity', 'Up to ');

    // Getting Listings row
    let listingsRow = {id: '2', title: 'Listings'}
    getNewKeysForObjectRow(listingsRow, 'listingsQuantity', 'Until ');

    // Getting Task Management row
    let taskManagementRow = {id: '3', title:' Task Management'}
    getNewKeysForObjectRow(taskManagementRow, 'tasksQuantity');

    // Getting default feature header row
    let defaultFeatureHeaderRow = {
      id: '4',
      title: <TitleComponent title={"Features"}/>
    }
    getNewKeysForObjectRow(defaultFeatureHeaderRow)

    const getFeatureRows = (initialIndex, canPurchaseAlone = false) => {
      return filter(info.features, {'canPurchaseAlone': canPurchaseAlone}).map((feature, index) => {
        let row = {
          id: `${initialIndex + index}`,
          title: feature.name,
        }

        info.inventoryItems.forEach((el, index) => {
          const searchElement = find(info.inventoryItemFeatures, {inventoryItemId: el.id, featureId: feature.id})
          const formatter = new Intl.NumberFormat('en-US')
          row[`tier${index + 1}`] = !!searchElement
            ? (feature.name !== 'CRM')
              ? <>{checkIcon} Included</>
              : el.listingsQuantity === -1
                ? `Unlimited`
                : `${formatter.format(el.listingsQuantity)} Clients`
            : '-'
        })

        return row;
      });
    }

    // Getting default feature rows
    const defaultFeatureRows = getFeatureRows(5, false)

    // Getting individual feature header row
    let individualFeatureHeaderRow = {
      id: `${5 + defaultFeatureRows.length}`,
      title: <TitleComponent title={"Individual features"} subtitle1={"Just needing specifics features?"}
                             subtitle2={"Contact us"} setModalBody={setModalBody} setShowModal={setShowModal}/>
    }
    getNewKeysForObjectRow(individualFeatureHeaderRow)

    // Getting individual feature rows
    const individualFeatureRows = getFeatureRows(6 + defaultFeatureRows.length, true)

    // Returning final content
    return ([
        teamMembersRow, listingsRow, taskManagementRow, defaultFeatureHeaderRow]
        .concat(defaultFeatureRows, [individualFeatureHeaderRow], individualFeatureRows)
    )
  }

  return (
    <div className="pricing-container">
      <NavigationBar ref={navigationBar}/>
      {
        isRendered &&
        <React.Fragment>
          <div className="first-row">
            <div className="intro">
              <label className="Title">Unlock the <span className='Highlight-Title'>power</span> of GoDealerGo</label>
              <label className="Cancel">Not sure if it is the solution you need? Get a <b>free 14 days trial</b></label>
            </div>
            <div className="tiers">
              {
                deployTiersList.map((item, index) => {
                  return (
                    <TierComponent
                      key={`tier-${index}`}
                      tier={item}
                      selectPlan={selectPlan}
                      recommended={index === 1}
                      setShowModal={setShowModal}
                      setModalBody={setModalBody}
                    />
                  )
                })
              }
            </div>
          </div>
          <div className="second-row">
            <div className="title-plans">
              <div className="title1">WE HAVE THE PERFECT PLAN FOR YOU</div>
              <div className="title2">Compare our plans</div>
            </div>
            <div className="table-plans">
              <div className='table-content'>
                <table>
                  <thead className="header-elements">
                  <tr>
                    {
                      deployHeadList.map((item, index) => {
                        const {text, text2} = item
                        return (
                          <th className={`th-tier-${index}`} key={`header-${index}`}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              <label className="title">{text}</label>
                              {
                                text2 &&
                                <label className="price">{text2}</label>
                              }</div>
                          </th>
                        )
                      })
                    }
                  </tr>
                  </thead>
                  <tbody className="body-elements">
                  {
                    deployBodyList.map((item, index) => {
                      const {title, tier1, tier2, tier3, tier4, id} = item
                      return (
                        <tr className={`tr-tier-details-${index}`} key={`body-${index}`}>
                          <td className={`title-details-${index}`}>{title}</td>
                          <td className={`tier1-det-${index}`}>{tier1}</td>
                          <td className={`tier2-det-${index}`}>{tier2}</td>
                          <td className={`tier3-det-${index}`}>{tier3}</td>
                        </tr>
                      )
                    })
                  }
                  <tr className="tr-tier-button">
                    {
                      [{id: 'unknownId'}].concat(deployTiersList).map((item, index) => {
                        const {plan} = item
                        return (
                          <td key={`button-${index}`}>
                            {
                              plan
                                ?
                                <GDGButton onClick={() =>
                                  browserHistory.push({
                                    pathname: '/marketing/accountCreation',
                                    state: {selectedPlan: item}
                                  })}
                                           hoverText={'Start free trial'} text={'Start free trial'}
                                           classNames={"table-button"}/>
                                : <></>
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {
            showModal &&
            <Modal show={showModal} onHide={() => setShowModal(false)} className={'container-modal request-demo-modal'}>
              <Modal.Body>
                {modalBody}
              </Modal.Body>
            </Modal>
          }
        </React.Fragment>
      }
    </div>
  )
}

export default Pricing