import React, {useEffect, useState} from 'react'
import {ReactSVG} from 'react-svg'
import {pathServer} from '../../../../../common/Constants'
import {DPSpinner} from '../../../../common/DPSpinner'
import SummaryInfoLayout, {calculatePrice, getCredit, getDaysToCurrentSubscriptionRemaining} from './SummaryInfoLayout'
import {getTeamMembersQuantity} from '../../DashboardUpdatePlan'
import {isEmpty} from 'lodash'

const SectionItem = (props) => {
  return (
    <div className={'subitem-plan'}>
      {
        props.included
          ? <React.Fragment>
            <div>
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_check_normal.svg'}
                beforeInjection={svg => svg.classList.add('icon-check')}
              />
            </div>
            <div>Included</div>
          </React.Fragment>
          : <div style={{fontSize: '19px'}}>-</div>
      }
    </div>
  )
}

const FeatureContainer = (props) => {
  return (
    <div className={'feature-container'}>
      <div className={'feature-title'}>{props.featureTitle}</div>
      {
        props.inventoryItemsList &&
        props.inventoryItemsList.map(el => {
          return (
            <div
              key={`feature-plan-${el.id}`}
              className={'feature-plan'}/>
          )
        })
      }
    </div>
  )
}

const InventoryItem = (props) => {
  const {inventoryItem: {id, price, name}, inventoryItem, onClickSelectBtn, selectedId, setSelectedId, disabled, currentInventoryItemId} = props
  const handleSelectButton = () => {
    onClickSelectBtn(inventoryItem)
    setSelectedId(id)
  }
  const isSelected = selectedId === id
  return(
    <div className={'plan-option'}>
      <div className={'plan-option-title'}>{name}</div>
      <div className={'plan-option-subtitle'}><b>{`US$ ${price}`}</b>/month</div>
      {
        id === currentInventoryItemId ?
          <div className={'selected-current-plan'}>Current</div> :
          <div>
            <button
              className={`select-plan-btn ${(isSelected || disabled)? "disabled-select-plan-btn" : ""} `}
              onClick = {handleSelectButton}
              disabled={isSelected || disabled}
            >
              {isSelected? "Selected" : "Select"}
            </button>
          </div>
      }
    </div>
  )
}

const FeatureSection = (props) => {
  return (
    <React.Fragment>
      <FeatureContainer
        featureTitle={props.featureTitle}
        inventoryItemsList={props.inventoryItemsList}
      />
      {
        props.featureList &&
        props.featureList.map(feature => {
          const {id} = feature
          return(
            <div key={`${props.preKeyString}-${id}`} className={'subitem-container'}>
              <div className={'subitem-title'}>{feature.name}</div>
              {
                props.inventoryItemsList &&
                props.inventoryItemsList.map(inventoryItem => {
                  const isIncluded = props.inventoryItemFeaturesList.find(x => { return x.featureId === id && x.inventoryItemId === inventoryItem.id })
                  return(
                    <SectionItem key={`iif-${id}-${inventoryItem.id}`} included={isIncluded}/>
                  )
                })
              }
            </div>
          )
        })
      }
    </React.Fragment>
  )
}

const UpdatePlanLayout = (props) => {
  const {inventoryItems, features, inventoryItemFeatures, individualFeatures,
    order, paymentMethodMain, nextInventoryItem, setNextInventoryItem, currentInventoryItemId
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const [selectedId, setSelectedId] = useState(null)

  // const currentInventoryItemId = order?.subscriptions?.length > 0 ? order.subscriptions[0].inventoryItemId : ''
  let currentInventoryItem
  if (!isEmpty(currentInventoryItemId)) {
    currentInventoryItem = inventoryItems?.find(ia => { return ia.id === currentInventoryItemId})
  }

  const daysDiff = getDaysToCurrentSubscriptionRemaining(order?.endDate ?? 0)
  const credit = getCredit(currentInventoryItem?.price ?? 0, daysDiff)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main id="update-plan-layout">
      {
        isLoading ?
          <DPSpinner /> :
          <>
            <section id="graphs">

              <div className={'plan-header-container'}>
                <div className={'plan-title'}/>
                {
                  inventoryItems &&
                  inventoryItems.map(inventoryItem => {
                    const {id, price} = inventoryItem
                    const totalAmount = calculatePrice(price ?? 0, credit)
                    return(
                      <InventoryItem
                        key={`inventory-item-${id}`}
                        inventoryItem={inventoryItem}
                        onClickSelectBtn={setNextInventoryItem}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        disabled={totalAmount <= 0}
                        currentInventoryItemId={currentInventoryItemId}
                      />
                    )
                  })
                }
              </div>

              <FeatureContainer
                featureTitle={'Limits'}
                inventoryItemsList={inventoryItems}
              />
              <div className={'subitem-container'}>
                <div className={'subitem-title'}>Team members</div>
                {
                  inventoryItems &&
                  inventoryItems.map(inventoryItem => {
                    return(
                      <div key={`team-members-${inventoryItem.id}`} className={'subitem-plan'}>
                        {getTeamMembersQuantity(inventoryItem.teamMembersQuantity, 'Up to')}
                      </div>
                    )
                  })
                }
              </div>

              <div className={'subitem-container'}>
                <div className={'subitem-title'}>Listings</div>
                {
                  inventoryItems &&
                  inventoryItems.map(inventoryItem => {
                    return(
                      <div key={`listings-${inventoryItem.id}`} className={'subitem-plan'}>
                        {getTeamMembersQuantity(inventoryItem.listingsQuantity, 'Until')}
                      </div>
                    )
                  })
                }
              </div>

              <div className={'subitem-container'}>
                <div className={'subitem-title'}>Task Management</div>
                {
                  inventoryItems &&
                  inventoryItems.map(inventoryItem => {
                    return (
                      <div key={`listings-${inventoryItem.id}`} className={'subitem-plan'}>
                        {getTeamMembersQuantity(inventoryItem.tasksQuantity)}
                      </div>
                    )
                  })
                }
              </div>

              <FeatureSection
                featureTitle={'Features'}
                featureList={features}
                preKeyString={'feature'}
                inventoryItemsList={inventoryItems}
                inventoryItemFeaturesList={inventoryItemFeatures}
              />

              <FeatureSection
                featureTitle={'Individual features'}
                featureList={individualFeatures}
                preKeyString={'individual-feature'}
                inventoryItemsList={inventoryItems}
                inventoryItemFeaturesList={inventoryItemFeatures}
              />

            </section>

            <SummaryInfoLayout
              order={order}
              paymentMethodMain={paymentMethodMain}
              nextInventoryItem={nextInventoryItem}
              currentInventoryItem={currentInventoryItem}
            />
          </>
      }
    </main>
  )
}

export default UpdatePlanLayout
