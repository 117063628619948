import {EmptyFunc, pathServer, WIDTH_VIEW_MOBILE} from '../../../../../common/Constants'
import React, { useEffect, useState } from 'react'
import {ReactSVG} from "react-svg";
import {browserHistory} from 'react-router'
require('./footerGDG.scss')

const contactEmail = "info@goDealergo.com"
const phone= '+1 801 494 9191'
const address = '227 N. 110 W. Vineyard UT, 84059'

const deployIconList = [
  {id:'1', route:'icon/ic-facebook-homepage.svg'},
  {id:'2', route:'icon/ic-twitter-homepage.svg'},
  {id:'3', route:'icon/ic-linkedin-homepage.svg'},
  {id:'4', route:'icon/ic-instagram-homepage.svg'},
]

const deployFeaturesList = [
  {id:'1', text:'Customer management', link: '/products/customerManagement'},
  {id:'2', text:'Reviews', link: '/products/reviews'},
  {id:'3', text:'Team Tasks', link: '/products/todoTeams'},
  {id:'4', text:'Website Builder', link: '/products/websiteBuilder'},
  {id:'5', text:'End-to-end sales process', link: '/products/salesProcess'},
  {id:'6', text:'Inventory management', link: '/products/inventoryManagement' },
]

const SocialMediaLinks = () => (
  <div className="social-media-links">
    {
      deployIconList.map((item) => {
        const {route, id} = item
        return (
          <ReactSVG
            key={`svg-${id}`}
            src={`${pathServer.PATH_IMG}${route}`}
            className="icon-menu-content"
          />
        )
      })
    }
  </div>
)

const FooterSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <footer id="footer-section-gdg" className="footer-section-gdg">
      <div className="content">
        <section className="footer-first-column">
          <img src={`${pathServer.PATH_IMG}gdg_logo_symbol.png`} alt="go-dealer-go-logo-symbol" className="logo"/>
          <div className="social-media-section">
            <label className="description">
              At GoDealerGo, we understand that running a successful dealership requires more than just great inventory—you need the right tools to attract, engage, and convert customers effectively.
              That’s why we’ve created a comprehensive, all-in-one platform designed specifically for dealers like you who are ready to take their digital operations to the next level.
            </label>
          </div>
        </section>
        <section className="footer-second-column">
          <h4 className="contact-title">Contact</h4>
          <label className="contact-info">
            <ul>
              <li><a href={`mailto:${contactEmail}`}>{contactEmail}</a></li>
              {/*<li><a href={`https://maps.google.com/maps?q=${address}`} target="_blank">{address}</a></li>*/}
              <li><a href={`tel:${phone}`}>{phone}</a></li>
              <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
            </ul>
          </label>
        </section>
        <section className="footer-third-column">
          <h4 className="feature-title">Features</h4>
          {
            deployFeaturesList.map((item) => {
              const { text, id, link } = item
              return (
                <span key={`text-${id}`}
                      onClick={link ? () => browserHistory.push({ pathname: link }) : EmptyFunc}>{text}</span>
              )
            })
          }
        </section>
      </div>
      <SocialMediaLinks/>
      <hr/>
      <div className="end-content">
        <div>
          <label className="brand-details">Copyright 2022 GDG all Rights reserved</label>
          {
            windowWidth <= WIDTH_VIEW_MOBILE &&
            <div>
              <a>Career</a>
              <a>Terms of use</a>
              <a>Privacy policy</a>
            </div>
          }
        </div>
      </div>
    </footer>
  )
}


export default FooterSection
