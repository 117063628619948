import React, {useState} from 'react'
import {DPInput} from '../../../../common/DPInput'
import DPSelectionDropDown from '../../../../common/DPSelectionDropDown'
import {pathServer, VALIDATION_EMAIL, VALIDATION_PHONE_NUMBER} from '../../../../../common/Constants'
import GDGButton, {BUTTON_TYPE} from '../GDGButton'
import {sendDemoEmail} from '../../../../Api'
import {checkValidEmail, checkValidPhone} from '../../../../../common/ValidationUtil'
import isEmpty from 'lodash/isEmpty'
import {validateError} from '../../../util/Utils'
import Loader from '../../../../../common/UI/Loader'
import DPIcon, {Icons} from '../../../../common/DPIcon'
import {browserHistory} from 'react-router'
import {DPInputElement} from "../../../../common/DPInputElement";
require('./DemoForm.scss')

const placeHolder = "Type here"
const title = "Request a demo"
const subtitle = "We are more than happy to walk you through our product and how we can help streamline your team’s processes."
const text = "TELL US A LITTLE BIT OF YOURSELF"

const listingsOptions = [
  {id:"0-15", displayValue: "0 - 15 listings"},
  {id:"16-40", displayValue: "16 - 40 listings"},
  {id:"41-70", displayValue: "41 - 70 listings"},
  {id:"71-more", displayValue: "more than 70 listings"}
]

const fieldsRequired = [
  {key: "name", displayValue: "Name "},
  {key: "company", displayValue: "Company"},
  {key: "email", displayValue: "Email"},
  {key: "phoneNumber", displayValue: "Phone Number"},
  {key: "listings", displayValue: "Listings"},
];

const getError = (object, errors) => {
  fieldsRequired.forEach( field => {
    const {key} = field;
    errors[key] = validateValue(field, object)
  });
  return errors
}

const validateValue = (field, object) => {
  const { key, displayValue} = field
  const value = object[key]
  switch (key){
    case VALIDATION_EMAIL:
      if(isEmpty(value) || (value === "")){
        return `The field ${displayValue} is required`
      }else{
        if(!checkValidEmail(value)){
          return `${displayValue} is not valid.`
        }else{
          return null
        }
      }
    case VALIDATION_PHONE_NUMBER:
      if(isEmpty(value) || (value === "")){
        return `The field ${displayValue} is required`
      }else{
        if(!checkValidPhone(value)){
          return `${displayValue} is not valid.`
        }else{
          return null
        }
      }
    default:
      if(isEmpty(value) || (value === "")){
        return `The field ${displayValue} is required`
      }
      return null
  }
}

const DemoForm = (props) => {
  const {showMessage, heightOfSelect = 42, plan = "", setShowBlurModal} = props
  const [form, setForm] = useState({ "listings" : plan })
  const [errorMessages, setErrorMessages] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const resetForm = (formId) => {
    document.getElementById(formId).reset();
  };

  const sendDemo = (e) => {
    const {showConfirmSent} = props

    const errors = getError(form, errorMessages);
    const hasError = validateError(errors);

    if(!hasError){
      setIsLoading(true)
      sendDemoEmail(form).then(response => {
        resetForm("demoForm")
        if(showConfirmSent){
          showConfirmSent()
        }
        setIsLoading(false)
      }).catch(error => {
        console.error('error:', error)
        setIsLoading(false)
      })
    }else{
      setErrorMessages({...errors})
    }

  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })

    setErrorMessages({
      ...errorMessages,
      [e.target.name]: null
    })
  }

  const setValue = (id, key) => {
    setForm({
      ...form,
      [key]: id
    })

    setErrorMessages({
      ...errorMessages,
      [key]: null
    })
  }

  const closeModal = () => {
    if(setShowBlurModal){
      setShowBlurModal()
    }else{
      browserHistory.push({pathname: ''})
    }
  }

  return(
    <div className="box-demo">
      { isLoading ? <Loader className="loader-demo"/> : null}
      <div className="container-icon-cancel">
        <DPIcon icon={Icons.CANCEL} onClick={closeModal}/>
      </div>
      <img className="logo-godealergo" src={pathServer.PATH_IMG + "godealergo.png"} alt=""/>
      <form id='demoForm' onSubmit={sendDemo} className="marketing-form">
        <h3>{title}</h3>
        <span className="subtitle">
          {subtitle}
        </span>
        <div className="container-text">
          <div className="line-left"/>
          <span>{text}</span>
          <div className="line-right"/>
        </div>
        <div className="container-inputs">
          <DPInput
            value={form.name}
            htmlFor="name"
            title="Name"
            type="text"
            id="name"
            placeholder={placeHolder}
            isRequired={true}
            onChange={handleChange}
            name="name"
            required={true}
            errorMessage={errorMessages.name}
          />
          <DPInput
            value={form.company}
            htmlFor="company"
            title="Business name"
            type="text"
            id="company"
            placeholder={placeHolder}
            isRequired={true}
            onChange={handleChange}
            name='company'
            errorMessage={errorMessages.company}
          />
          <DPInput
            value={form.email}
            htmlFor="email"
            title="Business email address"
            type="text"
            id="email"
            placeholder={placeHolder}
            isRequired={true}
            onChange={handleChange}
            name='email'
            errorMessage={errorMessages.email}
          />
          <DPInputElement
            value={form.phoneNumber}
            htmlFor="phoneNumber"
            title="Phone number"
            type="text"
            id="phoneNumber"
            placeholder={placeHolder}
            mask="(999) 999-9999"
            isRequired={true}
            onChange={handleChange}
            inputName='phoneNumber'
            errorMessage={errorMessages.phoneNumber}
          />
          <DPSelectionDropDown
            id='listings'
            label='How many listings do you have?'
            options={listingsOptions}
            value={form.listings}
            onClick={ (id) => setValue(id, 'listings')}
            heightOfSelect={heightOfSelect}
            isRequired={true}
            className="w100"
            errorMessage={errorMessages.listings}
          />
          <DPInput
            value={form.currentPain}
            htmlFor="currentPain"
            title="What are your current pain points in your process?"
            type="text"
            id="currentPain"
            placeholder={placeHolder}
            onChange={handleChange}
            name='currentPain'
            className={"w100"}
          />
          <DPInput
            value={form.expectation}
            htmlFor="expectation"
            title="Any expectation you have for this demo?"
            type="text"
            id="expectation"
            placeholder={placeHolder}
            onChange={handleChange}
            name='expectation'
            className={"w100"}
          />
          <DPInput
            value={form.comment}
            htmlFor="comment"
            title="Any other comment?"
            type="text"
            id="comment"
            placeholder={placeHolder}
            onChange={handleChange}
            name='comment'
            className={"w100 last-input"}
          />
          <span className="required-text">*Required</span>
          <div className="container-button-send">
            <GDGButton
              type={BUTTON_TYPE.BLUE}
              text={'Send demo request'}
              hoverText={'Send demo request now'}
              classNames="market-btn"
              onClick={() => sendDemo()}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default DemoForm