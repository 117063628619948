import React, {useEffect, useRef, useState} from "react";
import {
  TAB_BUSINESS_INFO,
  TAB_ADMIN_INFO,
  TAB_PAYMENT_INFO,
  TAB_VERIFICATION,
  ERROR_DUPLICATE_CODE,
  DOMAIN_URL_KEY,
  DUPLICATE_FIELDS
} from "../../../../Constants";
import NavigationBar from "../navigationBar/NavigationBar";
import {DPInput} from '../../../../common/DPInput'
//import DPButton from '../../../../../common/genericComponents/DPButton'
import DPButton from '../../../../common/DPButton'
import DPSelectionDropDown from "../../../../common/DPSelectionDropDown";
import {optionState, pathServer, SUCCESSFUL_CODE, TIME_FIVE_SECONDS} from '../../../../../common/Constants'
import {DPInputElement} from "../../../../common/DPInputElement";
import {getNewDate, validatePhoneNumber, validateZipCode} from "../../../util/Utils";
import { checkValidEmail, checkValidPhone } from '../../../../../common/ValidationUtil'
import { unstable_batchedUpdates } from 'react-dom'
import {ACCESS, PaymentMethod} from "../../../superusers/dealers";
import DPIcon, { Icons } from '../../../../common/DPIcon'
import ContactUs from '../contactUs/ContactUs'
import { Modal } from 'react-bootstrap'
import { browserHistory } from 'react-router'
import Moment from "moment/moment";
import {publicSaveNewDealerAndAccountOwner, verifyAdminEmail, verifyBusinessName } from "../../../../../catalog/Api";

require('./AccountCreation.scss')


const TabHeader = (props) => {
  const canSelect = props.filledForm.includes(props.tabKey)
  const containerClass = `tab ${props.isSelected ? 'tab-selected' : ''}`
  return (
    <>
      <div className={containerClass} >
        <label className={props.isSelected ? 'label-selected' : ''}>{props.headerText}</label>
      </div>
    </>
  )
}

const AddDealer = ({
                     currentTab,
                     changeTab,
                     setFilledForm,
                     filledForm,
                     setShowBlurModal,
                     selectedPlan
                   }) => {
  const initialFormState = {
    // Business Info
    businessName: '',
    businessPhoneNumber: '',
    address: '',
    zipCode: '',
    state: '',

    // Admin Info
    name: '',
    lastName: '',
    email: '',
    phone: ''
    // Payment Info
  };

  const initialErrorState = {
    // Business Info
    businessName: '',
    businessPhoneNumber: '',
    address: '',
    zipCode: '',
    state: '',
    businessDomain: '',
    [TAB_BUSINESS_INFO]: '',

    // Admin Info
    nameErrorMessage: '',
    lastNameErrorMessage: '',
    email: '',
    phoneErrorMessage: '',
    [TAB_ADMIN_INFO]: '',

    // Payment method Tab
    [TAB_PAYMENT_INFO]: '',
  };

  const [form, setForm] = useState(initialFormState)
  const [errors, setErrors] = useState(initialErrorState)
  const [isFormValidated, setIsFormValidated] = useState(false)
  const [showConfirmEmail, setShowConfirmEmail] = useState(false)
  const [comeFromVerification, setComeFromVerification] = useState(false)

  const handleChange = (name, value) => {
    setErrors((prevForm) => ({...prevForm, [name]: ''}))
    setForm((prevForm) => ({...prevForm, [name]: value}))
  }

  const selectOption = (element) => {
    unstable_batchedUpdates(() => {
      setErrors((prevErrors) => ({...prevErrors, state: ''}))
      setForm((prevForm) => ({...prevForm, state: element}))
    })
  }

  const ConfirmSendEmail = () => {
    const [isEmailResent, setIsEmailResent] = useState(false)

    const resendEmail = () => {
      setIsEmailResent(true)
    }

    const showContactUsModal = () => {
      setShowBlurModal(true)
    }

    const goToHomePage = () => {
      browserHistory.push({pathname: `/`})
    }

    return(
      <div className="check-mail-confirm-container">
        <div className="gif-mail-container">
          <img src={`${pathServer.PATH_IMG}mail-checked.gif`}/>
        </div>
        <div className="description-mail-confirm-container">
          <div className={isEmailResent ? "title-email-resent" : "title-email-sent"}>
            {isEmailResent ?
              <div className="email-resent-container"><DPIcon className={"icon-check-resent-email"} icon={Icons.CHECK_CIRCLE}/><div>Email Resent</div></div>
              : "Check Your Email"}
          </div>
          <div className="subtitle-email-confirm">
            <>Follow the instructions provided on your email <br/> to complete your registration</>
          </div>
        </div>
        <div className="buttons-email-confirm-container">
          <DPButton className={"resend-email-button"} onClick={resendEmail}>Resend email</DPButton>
          <DPButton className={"back-home-button"} onClick={goToHomePage}>Back to home</DPButton>
        </div>
        <div className="support-email-confirm-container">
          <span className="left-info-support">Did not receive any email?</span>
          <span className="right-info-support" onClick={showContactUsModal}>Contact us</span>
        </div>
      </div>
    )
  }

  const processPayment = async (payloadNonce = null, selectedPlan = null, requiredElements = true) => {
    if (payloadNonce === null && requiredElements) {
      return
    }

    const currentDate = Moment()
    let orderItems = null
    if (selectedPlan) {
      orderItems = [{
        inventoryItemId: selectedPlan.id,
        quantity: 1,
        unitPrice: selectedPlan.price,
        status: 'unpaid'
      }]
    }

    const newAccountOwner = {
      active: true,
      email: form.email,
      firstName: form.name,
      lastName: form.lastName,
      phoneNumber: form.phone,
      verify: false
    }

    const newDealer = {
      active: true,
      address1: form.address,
      email: "",
      name: form.businessName,
      phoneNumber: form.businessPhoneNumber,
      state: form.state,
      status: true,
      zip: form.zipCode
    }

    const fileAccountOwner = []
    const fileDealer = []
    const fileDealerIcon = []

    const body = {
      fileDealer: fileDealer,
      dealer: newDealer,
      fileAccountOwner: fileAccountOwner,
      accountOwner: newAccountOwner,
      fileDealerIcon,
      orderPayment: {
        order: {
          startDate: Moment(getNewDate(currentDate)).valueOf(),
          endDate: Moment(getNewDate(currentDate)).add(30, 'days').valueOf(),
          dueDate: Moment(getNewDate(currentDate)).add(40, 'days').valueOf(),
          taxAmount: 0,
          status: 'unpaid',
          dealerId: null,
          orderItems: orderItems,
        },
        nonce: payloadNonce,
      }
    }

    const response = await publicSaveNewDealerAndAccountOwner(body)
    const {responseCode, responseMessage, data} = response
    if (responseCode === SUCCESSFUL_CODE) {
      setShowConfirmEmail(true)
    } else {
      let currentTab = TAB_PAYMENT_INFO
      let newErrors = {...initialErrorState}

      if (data.code === ERROR_DUPLICATE_CODE) {
        if (data.field === DOMAIN_URL_KEY) {
          newErrors[TAB_BUSINESS_INFO] = 'This subdomain is already taken or does not have at least 3 letters.'
          newErrors.businessDomain = 'Please update this field.'
          // currentTab = TAB_BUSINESS_INFO
        } else if (data.field === DUPLICATE_FIELDS.NAME) {
          newErrors[TAB_BUSINESS_INFO] = responseMessage
          newErrors.businessName = 'Please update this field.'
          // currentTab = TAB_BUSINESS_INFO
        } else if (data.field === DUPLICATE_FIELDS.EMAIL) {
          newErrors[TAB_ADMIN_INFO] = responseMessage
          newErrors.email = 'Please update this field.'
          // currentTab = TAB_ADMIN_INFO
        } else
          newErrors[TAB_PAYMENT_INFO] = responseMessage
      }

      setErrors((prevErrors) => ({...prevErrors, ...newErrors}))
      changeTab(currentTab)

      /*setTimeout(() => {
        setErrors(initialErrorState)
      }, TIME_FIVE_SECONDS)*/
    }
  }

  const validate = async (tabName) => {
    let newErrors = {...initialErrorState}
    let hasErrors = false
    switch (tabName) {
      case TAB_BUSINESS_INFO:
        if (!form.businessName) {
          newErrors.businessName = "Please enter a business name."
          hasErrors = true
        }

        if (!form.businessPhoneNumber) {
          newErrors.businessPhoneNumber = "Please enter a phone number.";
          hasErrors = true;
        } else if (!validatePhoneNumber(form.businessPhoneNumber)) {
          newErrors.businessPhoneNumber = "Please enter a valid U.S. phone number.";
          hasErrors = true;
        }
        if (!form.address) {
          newErrors.address = "Please enter address."
          hasErrors = true
        }
        if (!form.state) {
          newErrors.state = "Please select a state."
          hasErrors = true
        }
        if (!form.zipCode) {
          newErrors.zipCode = "Please enter a zip code."
          hasErrors = true
        } else if (!validateZipCode(form.zipCode)) {
          newErrors.zipCode = "Please enter a valid zip code."
          hasErrors = true
        }

        const responseBusinessNameValidation = await verifyBusinessName(form.businessName)
        const { responseCode, data } = responseBusinessNameValidation
        if(responseCode === SUCCESSFUL_CODE &&  data.isValid){
          console.info(data.message)
        }else{
          newErrors.businessName = data.message
          hasErrors = true
        }

        setErrors((prevErrors) => ({...prevErrors, ...newErrors}))
        if (!hasErrors) {
          setComeFromVerification(false)
          changeTab(comeFromVerification ? TAB_VERIFICATION : TAB_ADMIN_INFO )
          setFilledForm(prev => {
            if (!prev.includes(TAB_BUSINESS_INFO)) {
              return [...prev, TAB_BUSINESS_INFO];
            }
            return prev;
          })
        }
        break;
    }
  }

  const Verification = ({form, setIsVerified, changeTab, setComeFromVerification}) => {
    const formAddress = `${form.address}, <br>${form.state}, ${form.zipCode}`
    const formName = `${form.name} ${form.lastName}`

    const formatPhoneNumber = (number) => {
      let formattedPhoneNumber = number.replace(/\D/g, "")
      formattedPhoneNumber = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3")
      return formattedPhoneNumber
    }

    const formBusinessPhoneNumber = formatPhoneNumber(form.businessPhoneNumber)
    const formPhone = formatPhoneNumber(form.phone)
    const handleContinue = () => {
      changeTab(TAB_PAYMENT_INFO)
    }
    const handleGoBack = () => {
      setComeFromVerification(true)
      changeTab(TAB_BUSINESS_INFO)
    }
    return (
      <div className="verification-container">
        <div className="business-info-section">
          <div className="business-name">
            <p className="label">Business name</p>
            <p className="value">{form.businessName}</p>
          </div>
          <div className="business-phone-number">
            <p className="label">Business phone number</p>
            <p className="value">{formBusinessPhoneNumber}</p>
          </div>
          <div className="address">
            <p className="label">Address</p>
            <p className="value">
              {form.address}<br/>
              {form.state}, {form.zipCode}
            </p>
          </div>
          <span className="link" onClick={handleGoBack}>Edit</span>
        </div>
        <div className="admin-info-section">
          <div className="name">
            <p className="label">Name</p>
            <p className="value">{formName}</p>
          </div>
          <div className="phone-number">
            <p className="label">Phone number</p>
            <p className="value">{formPhone}</p>
          </div>
          <div className="email">
            <p className="label">Email</p>
            <p className="value">{form.email}</p>
          </div>
          <span className="link" onClick={()=>changeTab(TAB_ADMIN_INFO)}>Edit</span>
        </div>
        <button className="submit-button" onClick={handleContinue}>Confirm and continue</button>
      </div>
    )
  }
  useEffect (()=> {
    if (currentTab === TAB_BUSINESS_INFO) {
      const isValid = form.businessName &&
        validatePhoneNumber(form.businessPhoneNumber) &&
        form.address &&
        form.state &&
        validateZipCode(form.zipCode)

      setIsFormValidated(isValid)
    }
    else if (currentTab === TAB_ADMIN_INFO) {
      const isValid = Boolean(form.name &&
        form.lastName &&
        checkValidEmail(form.email) &&
        checkValidPhone(form.phone))

      setIsFormValidated(isValid)
    }
  }, [form, currentTab])

  let child = null
  let tabDescription = null
  switch (currentTab) {
    case TAB_BUSINESS_INFO:
      child =
        <BusinessInfo
          changeTab={changeTab}
          handleChange={handleChange}
          form={form}
          validate={validate}
          errors={errors}
          tabName={TAB_BUSINESS_INFO}
          selectOption={selectOption}
          isFormValidated={isFormValidated}
        />
      tabDescription = <span>We collect your business information to customize your dashboard experience</span>
      break
    case TAB_ADMIN_INFO:
      child =
      <AdminInfo
        changeTab={changeTab}
        form={form}
        handleChange={handleChange}
        errors={errors}
        setErrors={setErrors}
        setFilledForm={setFilledForm}
        initialErrors={initialErrorState}
      />
      tabDescription = <span>We will use your admin info to create your account as <strong>admin</strong> so you can manage your business</span>
      break
    case TAB_PAYMENT_INFO:
      child =
      <PaymentMethod
        accessFrom={ACCESS.PUBLIC}
        classCardNumber={'form-group col-md-12'}
        classExpDate={'form-group col-md-6'}
        classCVV={'form-group col-md-6 cvv-container'}
        classBillingAddress={'form-group col-md-12'}
        classZipCode={'form-group col-md-12'}
        onClick={processPayment}
        selectedPlan={selectedPlan}
        errorMessage={errors[TAB_PAYMENT_INFO]}
      />
      break
    case TAB_VERIFICATION:
      child =
        <Verification
          form={form}
          changeTab={changeTab}
          setComeFromVerification={setComeFromVerification}
        />
      tabDescription='Review the information provided and edit it if needed'

  }

  return (
    <div className="container">
      {showConfirmEmail ? (
        <ConfirmSendEmail/>
      ) : (
        <>
          <div className={currentTab === TAB_PAYMENT_INFO ? 'only-one-tab' : "tabs"}>
            <div className="tab-title">
              {
                currentTab !== TAB_PAYMENT_INFO &&
                <>
                  <TabHeader
                    isSelected={currentTab === TAB_BUSINESS_INFO}
                    headerText='Business info'
                    changeTab={changeTab} tabKey={TAB_BUSINESS_INFO}
                    filledForm={filledForm}
                  />
                  <TabHeader
                    isSelected={currentTab === TAB_ADMIN_INFO}
                    headerText='Admin info'
                    changeTab={changeTab} tabKey={TAB_ADMIN_INFO}
                    filledForm={filledForm}
                  />
                  <TabHeader
                    isSelected={currentTab === TAB_VERIFICATION}
                    headerText='Verification'
                    changeTab={changeTab} tabKey={TAB_VERIFICATION}
                    filledForm={filledForm}
                  />
                </>
              }
              <TabHeader
                isSelected={currentTab === TAB_PAYMENT_INFO}
                headerText='Payment method'
                changeTab={changeTab} tabKey={TAB_PAYMENT_INFO}
                filledForm={filledForm}
              />
            </div>
            <div className="tab-description">
              {tabDescription}
            </div>
          </div>
          {child}
        </>
        )}
    </div>
  )
}

const BusinessInfo = ({handleChange, form, validate, errors, tabName, selectOption, isFormValidated, changeTab}) => {

  return (
    <div className="form">
      {
        errors[TAB_BUSINESS_INFO] && <div className={'error-message-tab'}>{errors[TAB_BUSINESS_INFO]}</div>
      }
      <DPInput
        value={form.businessName}
        title="Business name"
        type="text"
        id="businessName"
        className='input-container'
        placeholder="Enter your business name"
        isRequired={true}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        name="businessName"
        required={true}
        errorMessage={errors.businessName}
      />
      <DPInputElement
        value={form.businessPhoneNumber}
        title="Business phone number"
        type="text"
        id="businessPhoneNumber"
        className='input-container'
        placeholder="Enter your business phone number"
        mask="(999) 999-9999"
        isRequired={true}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        inputName="businessPhoneNumber"
        required={true}
        errorMessage={errors.businessPhoneNumber}
      />
      <DPInput
        value={form.address}
        title="Address"
        type="text"
        id="address"
        className='input-container'
        placeholder="Enter your business address"
        isRequired={true}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        name="address"
        required={true}
        errorMessage={errors.address}
      />
      <DPInput
        value={form.zipCode}
        title="Zip Code"
        type="text"
        id="zipCode"
        className='input-container'
        placeholder="Enter your zip code"
        isRequired={true}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        name="zipCode"
        required={true}
        errorMessage={errors.zipCode}
      />
      <div>
        <DPSelectionDropDown
          options={optionState}
          className='select-container'
          label='State'
          isRequired='true'
          onClick={selectOption}
          value={form.state}
          errorMessage={errors.state}
        />
      </div>
      <DPButton
        width="100px"
        height="80px"
        // className={isFormValidated ? "submit-button" : "disabled-button"}
        className={isFormValidated ? "submit-button" : "button-disabled"}
        onClick={() => validate(tabName)}
      >Continue</DPButton>
    </div>
  )
}

const AdminInfo = ({changeTab, form, handleChange, errors, setErrors, setFilledForm, initialErrors}) => {
  const [isAdminInfoFormValidated, setIsAdminInfoFormValidated] = useState(false)

  const validate = async () => {
    let newErrors = {}
    let hasErrors = false

    if (!form.name || !(form.name.trim())) {
      newErrors.name = 'Please enter a name'
      hasErrors = true
    }

    if (!form.lastName || !(form.lastName.trim())) {
      newErrors.lastName = 'Please enter a last name'
      hasErrors = true
    }

    if (!form.email || !(form.email.trim()) || !checkValidEmail(form.email.toLowerCase())) {
      newErrors.email = 'Please enter a valid email address'
      hasErrors = true
    }

    if (!form.phone || !(form.phone.trim()) || !checkValidPhone(form.phone)) {
      newErrors.phone = 'Please enter a valid phone number.'
      hasErrors = true
    }

    const responseAdminEmailValidation = await verifyAdminEmail(form.email)
    const { responseCode, data } = responseAdminEmailValidation
    if(responseCode === SUCCESSFUL_CODE &&  data.isValid){
      console.info(data.message)
    }else{
      newErrors.email = data.message
      hasErrors = true
    }


    setErrors(prevErrors => ({ ...prevErrors, ...newErrors }))

    if (!hasErrors) {
      changeTab(TAB_VERIFICATION)
      setErrors(initialErrors)
      setFilledForm(prev => {
        if (!prev.includes(TAB_ADMIN_INFO)) {
          return [...prev, TAB_ADMIN_INFO];
        }
        return prev;
      })
    }
  }

  useEffect(() => {
    const isAdminFormValid = form.name &&
      form.lastName &&
      checkValidPhone(form.phone) &&
      checkValidEmail(form.email)
    setIsAdminInfoFormValidated(isAdminFormValid)
  }, [form.name, form.lastName, form.phone, form.email])

  return (
    <div className="form-admin-info">
      {
        errors[TAB_ADMIN_INFO] && <div className={'error-message-tab'}>{errors[TAB_ADMIN_INFO]}</div>
      }
      <div className="first-row-admin-info">
        <DPInput
          id={"nameAdmin"}
          className={"name-admin"}
          title={"Name"}
          placeholder={"Enter your name"}
          value={form.name}
          onChange={(e) => handleChange('name', e.target.value)}
         required={true}
         isRequired={true}
        errorMessage={errors.name}/>
        <DPInput
          id={"lastNameAdmin"}
          className={"last-name-admin"}
          title={"Last name"}
          placeholder={"Enter your last name"}
          value={form.lastName}
          onChange={(e) => handleChange('lastName', e.target.value)}
          required={true}
          isRequired={true}
        errorMessage={errors.lastName}/>
      </div>
      <div className="second-row-admin-info">
        <DPInput
          id={"emailAdmin"}
           className={"email-admin"}
          title={"Email"}
          placeholder={"Enter your email"}
          value={form.email}
          onChange={(e) => handleChange('email', e.target.value)}
          required={true}
          isRequired={true}
        errorMessage={errors.email}/>
        <DPInputElement
          value={form.phone}
          title="Phone number"
          type="text"
          id="phoneAdmin"
          className={"phone-admin"}
          placeholder={"Enter your phone number"}
          mask="(999) 999-9999"
          isRequired={true}
          onChange={(e) => handleChange('phone', e.target.value.replace(/\D/g, ""))}
          inputName="businessPhoneNumber"
          required={true}
          errorMessage={errors.phone}
        />
      </div>
      <div className="button-row-admin-info">
        <DPButton id={"continueAdminInfoButton"} className={isAdminInfoFormValidated ? "continue-admin-info-button-enable" : "continue-admin-info-button-disable"} label={"Continue"} borderStyle={"6px"} onClick={validate}>Continue</DPButton>
      </div>
    </div>
  )

}

const AccountCreation = (props) => {
  const [selectedTab, setSelectedTab] = useState(TAB_BUSINESS_INFO)
  const handleChangeTab = (tab) => setSelectedTab(tab)
  const navigationBar = useRef();
  const [filledForm, setFilledForm] = useState([])
  const [showBlurModal, setShowBlurModal] = useState(false)

  return (
    <div className='page-container'>
      <NavigationBar isAccountCreation={true} ref={navigationBar}/>
      <AddDealer currentTab={selectedTab} changeTab={handleChangeTab} setFilledForm={setFilledForm}
                 filledForm={filledForm} setShowBlurModal={setShowBlurModal}
                 selectedPlan={props.location.state.selectedPlan}/>
      {
        showBlurModal &&
        <Modal show={showBlurModal} onHide={() => setShowBlurModal(false)} className={'container-modal request-demo-modal'}>
          <Modal.Body>
            <ContactUs isModal={true}/>
          </Modal.Body>
        </Modal>
      }
    </div>
  )
}

export default AccountCreation
