import {pathServer, SUCCESSFUL_CODE} from '../../../../common/Constants'
import React, { useEffect, useRef, useState } from 'react'
import DPCheckbox from '../../../common/DPCheckbox'
import DPButtonText from '../../../../common/DPButtonText'
import { browserHistory } from 'react-router'
import { checkValidEmail } from '../../../../common/ValidationUtil'
import { signInApi } from '../../../Api'
import RecoverPassword from '../../register/RecoverPassword'
import DPIcon, { Icons } from '../../../common/DPIcon'
import NavigationBar from './navigationBar/NavigationBar'
import { unstable_batchedUpdates } from 'react-dom'

require('../homepage.scss')

const sessionExpiredMessage = "Your session has expired, please sign in again."

const NewLogin = (props) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showRecover, setShowRecover] = useState(false)
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isEmptyPassword, setIsEmptyPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [returnChangePassword, setReturnChangePassword] = useState(false)
  const [keepLoggedIn, setKeepLoggedIn] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const navigationBar = useRef();


  useEffect(() => {
    setIsSessionExpired(getCookieByName('userData') === null)
  },[])

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.changePassword === 'changePassword')
        setReturnChangePassword(true)
      else
        setReturnChangePassword(false)
    }
  }, [props])

  const recoverPassword = () => {
    if (checkValidEmail(email)) {
      setShowRecover(true)
    } else {
      setIsValidEmail(false)
    }
  }

  const resetPassword = () => {
    unstable_batchedUpdates(() => {
      setIsValidEmail(true)
      setShowResetPassword(true)
    })
  }

  const goSignIn = () => {
    unstable_batchedUpdates(() => {
      setShowResetPassword(false)
      setShowRecover(false)
    })
  }

  function getCookieByName(name) {
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name + '=') === 0) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  const getKeepLoggedInFromCookie = () => {
    const userData = getCookieByName('userData');
    if (userData) {
      const decodedData = atob(userData)
      const parsedData = JSON.parse(decodedData);
      return parsedData.keepLoggedIn || false;
    }
    return false;
  }

  const login = () => {

    const keepLoggedIn = getKeepLoggedInFromCookie();

    if (!checkValidEmail(email)) {
      setIsValidEmail(false)
    } else if (password === '') {
      setIsEmptyPassword(true)
    }else{
      signInApi({ email: email, password: password, keepLoggedIn: keepLoggedIn }).then(
        response => {
          const {responseCode, responseMessage} = response
          if(responseCode === SUCCESSFUL_CODE){
            window.location.href = '/main'
          }else{
            console.log('error ==> ', responseMessage)
            setErrorMessage(responseMessage)
          }
        })
    }
  }

  const handleKeepLoggedIn = (e) => {
    setKeepLoggedIn(e.target.checked)
  }

  const handlePassword = (e) => {
    unstable_batchedUpdates(()=> {
      setPassword(e.target.value)
      setIsEmptyPassword(false)
      setErrorMessage(null)
    })
  }

  const handleEmail = (e) => {
    unstable_batchedUpdates(() => {
      setEmail(e.target.value)
      setIsValidEmail(true)
      setErrorMessage(null)
    })
  }

  const handleRecoverEmail = (e) => {
    unstable_batchedUpdates(() => {
      setEmail(e.target.value)
      setIsValidEmail(true)
      setErrorMessage(null)
    })
  }

  return (
    <div className="newLogin-section-gdg">
      <NavigationBar isAccountCreation={true} ref={navigationBar}/>
      <div className="login-main-container">
        <div className="login-image-container">
          {showResetPassword ?
            <img src={`${pathServer.PATH_IMG}change-password.gif`}/>
            :
            <img src={`${pathServer.PATH_IMG}newLoginV2.png`}/>
          }
        </div>
        <div className="login-credentials-container">
          {(props.children) ? React.cloneElement(props.children) :
            showResetPassword ?
              (showRecover) ?
                (<RecoverPassword email={email} backLogin={goSignIn}/>) :
                (
                  <div className="login-form-container">
                    <div className="login-title">Reset Password</div>
                    <div className="credentials-container">
                      <label className="email-login">Enter a registered email</label>
                      <input className="email-input" type="text" placeholder="Email address" value={email}
                             onKeyUp={e => e.keyCode === 13 ? recoverPassword() : null}
                             onChange={handleRecoverEmail}/>
                      {!isValidEmail ? <span className="message-error">We couldn't find this email, please provide a verified email.</span> : null}
                    </div>
                    <DPButtonText buttonClass={'sign-in-button'} textButton={'Reset'} width={'inherit'} height={'45px'}
                                  textColor={'#FFF'} fontSizeText={'18px'} onClick={recoverPassword}/>
                  </div>
                )
              :
              <div className="login-form-container">
                <div className="login-title">Welcome</div>
                <DPButtonText
                  iconPath={'ic_google_color.svg'}
                  textButton={'Login with Google'}
                  width={'80%'}
                  buttonClass={'google-btn'}
                />
                <div className="first-separator">
                  <div className="hr-left">
                    <hr/>
                  </div>
                  <label className="separator-description">OR LOGIN WITH EMAIL</label>
                  <div className="hr-right">
                    <hr/>
                  </div>
                </div>
                <div className="credentials-container">
                  {errorMessage ? <span className="message-general-error">{errorMessage}</span> : null}
                  <label className="email-login">Email</label>
                  <input className="email-input" type="text" placeholder="Enter your email address" value={email}
                         onKeyUp={e => e.keyCode === 13 ? recoverPassword() : null}
                         onChange={handleEmail}/>
                  {!isValidEmail ? <span className="message-error">Please provide a username or email</span> : null}
                  <label className="password-login">Password</label>
                  <input className="password-input" type="password" placeholder="Enter your password" value={password}
                         onKeyUp={e => e.keyCode === 13 ? login() : null}
                         onChange={handlePassword}/>
                  {isEmptyPassword ? <span className="message-error">Please provide password</span> : null}
                </div>
                <div className="keep-password-container">
                  <div className="checkbox-container">
                    <DPCheckbox
                      className="checkbox-keep"
                      onChange={handleKeepLoggedIn}
                    />
                    <label className="description-keep">Keep me logged in</label>
                  </div>
                  <label onClick={resetPassword} className="forgot-container">Forgot you password?</label>
                </div>
                <DPButtonText buttonClass={'sign-in-button'} textButton={'Sign in'} width={'inherit'} height={'45px'}
                              textColor={'#FFF'} fontSizeText={'18px'} onClick={login}/>
                <div className="second-separator">
                  <hr/>
                </div>
                <div className="sign-up-container">
                  <label className="sign-up-1">Don't have an account?</label>
                  <label className="sign-up-2">Sign up</label>
                </div>
              </div>
          }
        </div>
      </div>


    </div>
  )
}
export default NewLogin
