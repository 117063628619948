import React, { Component, useRef, useState } from 'react'
import {pathServer, SUCCESSFUL_CODE} from '../../common/Constants'
import {changePasswordAccount, verifyUser} from '../Api'
import {ReactSVG} from 'react-svg'
import NavigationBar from '../newComponents/homePage/components/navigationBar/NavigationBar'
import { unstable_batchedUpdates } from 'react-dom'
import FooterSection from '../newComponents/homePage/components/footer/FooterSection'
import { colorPassword } from '../Constants'

require ('./accountConfirmationV2.scss')

const AccountConfirmationV2 = (props) => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isEmptyPassword, setIsEmptyPassword] = useState(true)
  const [isEmptyConfirmPassword, setIsEmptyConfirmPassword] = useState(true)
  const [matchPassword, setMatchPassword] = useState(null)
  const [meetRequirements, setMeetRequirements] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [requirements, setRequirements] = useState([false, false, false, false])

  const {userId, verify} = props.params;
  const verificationOnly = verify === "verify"
  const navigationBar = useRef();


  const changePassword = () => {
    const {userId} = props.params;

    if (password === "") {
      unstable_batchedUpdates(() => {
        setPassword("")
        setMeetRequirements(false)
        setIsEmptyConfirmPassword(true)
        setMatchPassword(false)
      })
      return;
    }
    if (requirements.toString() !== "true,true,true,true") {
      unstable_batchedUpdates(() => {
        setIsEmptyPassword(false)
        setMeetRequirements(false)
        setIsEmptyConfirmPassword(false)
        setMatchPassword(false)
      })
      return;
    }
    if (confirmPassword === "") {
      unstable_batchedUpdates(() => {
        setIsEmptyPassword(false)
        setMeetRequirements(true)
        setIsEmptyConfirmPassword(true)
        setMatchPassword(false)
      })
      return;
    }
    if(password !== confirmPassword){
      unstable_batchedUpdates(()=> {
        setIsEmptyPassword(false)
        setMeetRequirements(true)
        setIsEmptyConfirmPassword(false)
        setMatchPassword(true)
      })
      return;
    }
    if (password !== '' && password === confirmPassword){
      unstable_batchedUpdates(()=> {
        setIsEmptyPassword(false)
        setMeetRequirements(true)
        setIsEmptyConfirmPassword(false)
        setMatchPassword(true)
      })
      const data = {userId:userId,password:password,confirmPassword:confirmPassword, verify:true};
      changePasswordAccount(data).then((response) =>{
        //browserHistory.push("/main")
        const {responseCode, responseMessage} = response
        if(responseCode === SUCCESSFUL_CODE){
          window.location.href = "/login"
        }else{
          this.setState({errorMessage: responseMessage})
        }
      })
    }
  }

  const handleVerifyUser = () => {
    const {userId} = this.props.params;
    verifyUser(userId).then((response) => {
      window.location.href = "/login"
    }).catch((error) => {
      console.error(error)
    })
  }
  const handleChange = (e) => {
    const valueRequirementsToUpdate = [false, false, false, false];
    const password = e.target.value;

    unstable_batchedUpdates(() => {
      if (password.match(/[A-Z]/g)) {
        valueRequirementsToUpdate[0] = true;
      }
      if (password.length >= 8) {
        valueRequirementsToUpdate[1] = true;
      }
      if (password.match(/[^a-zA-Z\d]/g)) {
        valueRequirementsToUpdate[2] = true;
      }
      if (password.match(/[0-9]/g)) {
        valueRequirementsToUpdate[3] = true;
      }
      setRequirements(valueRequirementsToUpdate)
      setPassword(password)
      setIsEmptyPassword( password!=="" ? false : null)
    })
  }

  const handleConfirmPassword = (e) => {
    let confirmPassword = e.target.value;
    unstable_batchedUpdates(() => {
      setConfirmPassword(confirmPassword)
      setIsEmptyConfirmPassword( confirmPassword!== "" ? false : null)
      setMatchPassword(password !== "" && confirmPassword !== "" && password === confirmPassword);
      setMeetRequirements(requirements.toString() === "true,true,true,true")
    })
  }

    return (
      <div className="account-confirmation">
        <div id="id-background" className="background"/>
        <NavigationBar isAccountCreation={true} ref={navigationBar}/>

        {verificationOnly
          ? (
            <>
              <p className="title">Almost there</p>
              <p className="sub-title">Please click to verify</p>
              <div className="text-center">
                <button className="btn-start" onClick={handleVerifyUser}>Verify</button>
              </div>
            </>
          )
          : (
            <div className={"container-form-create-password"}>
              <p className="title">Create password</p>
              <p className="sub-title">Secure your account by creating a strong password.</p>
              <div className="form-content">
                <div className={'new-password-container'}>
                  <div className="box-new-password-recover">
                    <>
                      <label className={"label-info"}>Create your password</label><label className={"label-error"}>*</label>
                    </>
                    <input className={"password-input"} type="password" placeholder="Password" value={password} onChange={handleChange}
                    style={{borderColor: (matchPassword === true && confirmPassword !== "" && password !== "" && meetRequirements === true) ? colorPassword.greenBorderFieldPassword : null}}/>
                    {password !== "" && confirmPassword !== "" && !meetRequirements
                      ? <span className="message-general-error">Your password does not meet the required criteria.</span>
                      : null
                    }
                  </div>
                </div>
                <div className={"confirm-password-container"}>
                  <div className="box-new-password-recover">
                    <>
                      <label className={"label-info"}>Repeat password</label><label className={"label-error"}>*</label>
                    </>
                    <input className={"password-input"} type="password" placeholder="Type here" value={confirmPassword} onChange={handleConfirmPassword}
                           style={{borderColor: (matchPassword === true && confirmPassword !== "" && password !== "" && meetRequirements === true) ? colorPassword.greenBorderFieldPassword : null}}/>
                    {password !== "" && confirmPassword !== "" && !matchPassword
                      ? <span className="message-error">The passwords do not match. Please try again.</span>
                      : null
                    }
                  </div>
                </div>
                <div className="requirements-box">
                  <div className="form-group-login">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_normal.svg"} className="icon-arrow-green"
                              style={{ fill: !requirements[1] ? colorPassword.grayBorderFieldPassword : colorPassword.greenBorderFieldPassword }}/>
                    At least 8 characters
                  </div>
                  <div className="form-group-login">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_normal.svg"} className="icon-arrow-green"
                              style={{ fill: !requirements[0] ? colorPassword.grayBorderFieldPassword : colorPassword.greenBorderFieldPassword }}/>
                    A capital letter
                  </div>
                  <div className="form-group-login">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_normal.svg"} className="icon-arrow-green"
                              style={{ fill: !requirements[2] ? colorPassword.grayBorderFieldPassword : colorPassword.greenBorderFieldPassword }}/>
                    A special symbol (!”#$%&/?¡)
                  </div>
                  <div className="form-group-login">
                    <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_normal.svg"} className="icon-arrow-green"
                              style={{ fill: !requirements[3] ? colorPassword.grayBorderFieldPassword : colorPassword.greenBorderFieldPassword }}/>
                    A number (1234567890)
                  </div>
                </div>
                <div className="text-center">
                  <button className={matchPassword && meetRequirements && password!== "" && confirmPassword !=="" ?'btn-start' : 'btn-disabled'}
                          onClick={matchPassword && meetRequirements && password!== "" && confirmPassword !=="" ? changePassword : null} >Create password</button>
                </div>

              </div>
            </div>
          )
        }
        <FooterSection/>
      </div>
    )

}

export default AccountConfirmationV2
